import React, { useState, useRef, memo } from "react";
// import _ from "lodash";
import settingsSearchIcon from "../../styles/img/svg/settings-search-icon1.svg";
// import worloadConstant from "../../constants/workloadConstant";
import OutsideClickHandler from "react-outside-click-handler";
import firebase from "../../firebase";
import Scrollbar from "react-scrollbars-custom";
import { getIsSuperAdmin } from "../../helpers/reportlanding";

function WorkloadProjectSelectArea({
  selectedProject,
  listOfProjects,
  onProjectSelected,
  onSearchInitiated,
  logedinUserDetails,
  workspace,
}) {
  const [showDropDown, setShowDropDown] = useState("");
  const [, setClickedProject] = useState({
    id: 0,
    name: "All Projects",
  });
  const [updatedProject, setUpdatedProject] = useState(null);
  const [searchText, setSearchText] = useState("");

  const showOptionsNode = useRef(null);

  let projectData = updatedProject || listOfProjects;

  const renderProjectList = (getIsSuperAdmin(logedinUserDetails, workspace)
    ? projectData
    : projectData.filter(project => {
        return (
          project.data.acl &&
          project.data.acl.includes(parseInt(logedinUserDetails.id))
        );
      })
  ).map(project => {
    return (
      <div
        key={project.id}
        onClick={() => {
          setClickedProject(project);
          onProjectSelected(project);
          setShowDropDown(false);
        }}
        className={
          selectedProject.id === project.id
            ? "selected-project-wl project-wl-list"
            : "project-wl-list"
        }
      >
        {project.name}
      </div>
    );
  });

  const onChangeSearch = event => {
    let searchText = event.target.value;
    let updatedWorkSpaceUsers = listOfProjects.filter(
      project =>
        project.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
    );
    setUpdatedProject(updatedWorkSpaceUsers);
    setSearchText(searchText);
  };

  return (
    <div className="wl-project-container" ref={showOptionsNode}>
      <div
        className={`title-wl-holder ${showDropDown ? "clicked" : ""}`}
        role="button"
        tabIndex="0"
        onClick={e => {
          firebase.analytics().logEvent("GTD_Workload_Project_Dropdown");
          e.preventDefault();
          e.stopPropagation();
          setShowDropDown(!showDropDown);
        }}
      >
        {selectedProject.name}
      </div>
      <OutsideClickHandler
        onOutsideClick={e => {
          if (showOptionsNode && !showOptionsNode.current.contains(e.target))
            setShowDropDown(false);
          setUpdatedProject(null);
        }}
      >
        {showDropDown && (
          <div className="project-list-container-wl">
            <div className="search-wl-wrap">
              <img src={settingsSearchIcon} alt="search-icon" />
              <input
                type="search"
                id="search-people"
                placeholder="Search"
                aria-invalid="false"
                onChange={onChangeSearch}
              />
            </div>

            <Scrollbar style={{ height: "auto", minHeight: "100px" }}>
              <div>
                <div className="repeat-wl">
                  <div
                    key={0}
                    onClick={() => {
                      setClickedProject({
                        id: 0,
                        name: "All Projects",
                      });
                      onProjectSelected({
                        id: 0,
                        name: "All Projects",
                      });
                      setShowDropDown(false);
                    }}
                    className={
                      selectedProject.id === 0
                        ? "selected-project-wl project-wl-list"
                        : "project-wl-list"
                    }
                  >
                    All Projects
                  </div>
                  {renderProjectList}
                </div>
              </div>
            </Scrollbar>
          </div>
        )}
      </OutsideClickHandler>
      <div></div>
    </div>
  );
}

export default memo(WorkloadProjectSelectArea);
