import React, { useState, memo } from "react";
import searchIcon from "../../styles/img/svg/settings-search-icon1.svg";
import closeIcon from "../../styles/img/svg/settings-close-icon.svg";

const SettingsSearch = props => {
  const [searchText, setSearchText] = useState("");

  const onChange = event => {
    props.onShowSearch(event.target.value);
    setSearchText(event.target.value);
  };

  const onCloseSearch = () => {
    props.onShowSearch("");
    setSearchText("");
    props.onCloseSearch();
  };

  const { placeholder } = props;
  return (
    <div className="wrap-input-cross">
      <img src={searchIcon} alt="search-icon" />
      <input
        value={searchText}
        onChange={onChange}
        autoComplete="off"
        className="ppl-search-control searchBar"
        type="text"
        id="search-people"
        placeholder={`Search ${placeholder.toLowerCase()}`}
        autoFocus
      />
      <div className="search-settings-project">
        <img src={closeIcon} onClick={onCloseSearch} alt="close-icon" />
      </div>
    </div>
  );
};

export default memo(SettingsSearch);
