import React, { PureComponent } from "react";
import Mark from "mark.js";
import "../../styles/scss/activity.scss";
import "../../styles/scss/activityPopup.scss";
import ActivityTile from "./activitytile";
import PriorityActivity from "./priorityactivity";
import { connect } from "react-redux";
import { activities } from "../../constants/activitiescontants";
import SelectField from "../../components/common/partials/selectField";
import _ from "lodash";
import DialogWrapper from "../common/dialog";
import ActivityPopupTop from "./activityPopupTop";
import ActivityPopupBottom from "./activityPopupBottom";
import TimerCannotStartPopup from "./timerCannotStartPopup";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
import { getAllCustomerForPeopleAction } from "../../actions/people/peopleaction";
import InfiniteScroll from "react-infinite-scroll-component";
import { Loader } from "../common/loader";
import {
  getDeprioritizedActivitiesForWorkspaceAction,
  getTimerStatusForWorkspaceAction,
  clearReducerAction,
} from "../../actions/workspace/workspaceaction";
import {
  getActivityByIdAction,
  getActivityByIdForNotificationAction,
  removeActivityFromReducerAction,
} from "../../actions/activities/activitiesaction";
import firebase from "../../firebase";
// import { getCustomRoutePath } from "../../helpers/generichelper";
momentDurationFormatSetup(moment);

class ActiveActivities extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sortByDeprioritized: JSON.parse(
        localStorage.getItem("activity-sorting")
      ) || { id: 3, text: "Date Created" },
      popupActivityDetails: {},
      isPopupEnable: false,
      is_priority: false,
      page: 1,
      isScrollable: false,
      showCannotPrioritizePopup: false,
      errorObj: null,
      // switchWorkspaceCalled: false
    };
    this.onPopupCloseCallBack = this.onPopupCloseCallBack.bind(this);
    this.onArchiveItem = this.onArchiveItem.bind(this);
  }

  componentDidMount() {
    /** Remove multiple calls to workspace users */
    // const { getAllCustomerForPeopleAction } = this.props;
    // getAllCustomerForPeopleAction((response) => {
    //   //console.log(response);
    // });

    const { router, getActivityByIdForNotificationAction } = this.props;
    if (
      router.location.query.customer_id &&
      router.location.query.activity_id
    ) {
      if (
        parseInt(localStorage.getItem("customer_id")) ===
        parseInt(router.location.query.customer_id)
      ) {
        const activityId = parseInt(router.location.query.activity_id);
        getActivityByIdForNotificationAction({ id: activityId }, (res) => {
          if (res?.length > 0) {
            // console.log("have actid", res[0]);
            this.handleShowPopup(res[0]);
          }
        });
      }
    }
  }

  // static getDerivedStateFromProps(props, state) {
  //   const { router, listOfCustomer, onWorkspaceNavBarClicked, getActivityByIdForNotificationAction } = props;
  //   if(props.selectedCustomerDetails?.id && router.location.query.customer_id && listOfCustomer.length > 0 && !state.switchWorkspaceCalled) {
  //     const customerId = parseInt(router.location.query.customer_id);
  //     if(props.selectedCustomerDetails.id !== customerId) {
  //       const workspaceToChange = listOfCustomer?.length > 0 &&
  //         listOfCustomer.filter(customer => customer.id === customerId)[0];
  //       const routePath = getCustomRoutePath("/activity", router.location.query);
  //       console.log("RoutePath", routePath);
  //       workspaceToChange && onWorkspaceNavBarClicked(null, workspaceToChange, routePath);
  //       return {
  //         ...state,
  //         switchWorkspaceCalled: true
  //       }
  //     }
  //   }
  //   if(state.switchWorkspaceCalled && router.location.query.customer_id) {
  //     const activityId = router.location.query.activity_id;
  //     getActivityByIdForNotificationAction({ id: activityId }, res => {
  //       if(res?.length > 0) {
  //         console.log("have actid", res[0]);
  //         this.handleShowPopup(res[0]);
  //       }
  //     })
  //   }
  //   return null;
  // }

  // componentWillUnmount() {
  //   const { clearReducerAction } = this.props;
  //   clearReducerAction(res => {});
  // }
  dropDownOptions = [
    { id: 1, text: "Activity" },
    { id: 2, text: "Project" },
    { id: 3, text: "Date Created" },
    { id: 4, text: "Due Date" },
  ];

  onClickDropDownOptions = (clickedOption) => {
    firebase.analytics().logEvent("GTD_Activity_sorted");
    this.setState({
      sortByDeprioritized: clickedOption,
    });
    localStorage.setItem("activity-sorting", JSON.stringify(clickedOption));
  };

  handleShowPopup = (activityDetails) => {
    firebase.analytics().logEvent("GTD_Activity_inner");
    const { prioritiZedActivity, isPopupEnableInnerClicked, getActivityById } = this.props;
    const is_priority =
      prioritiZedActivity.filter(
        (activity) => activity.id === activityDetails.id
      ).length > 0
        ? true
        : false;

    getActivityById({ id: activityDetails.id, user_id: parseInt(localStorage.getItem("id")) });

    isPopupEnableInnerClicked();
    if (!activityDetails.workflow) {
      activityDetails = {
        ...activityDetails,
        workflow: { id: 1, name: "Backlog" },
      };
    }
    this.setState(() => ({
      isPopupEnable: true,
      popupActivityDetails: activityDetails,
      is_priority: is_priority,
    }));
  };

  onPopupCloseCallBack() {
    const {
      isPopupEnableInnerDisableClicked,
      removeActivityFromReducer,
    } = this.props;
    isPopupEnableInnerDisableClicked && isPopupEnableInnerDisableClicked();
    this.setState(
      () => ({
        isPopupEnable: false,
        // popupActivityDetails: {},
        // is_priority: null
      }),
      () => {
        if (
          !this.state.popupActivityDetails?.user_id?.includes(
            parseInt(localStorage.getItem("id"))
          )
        ) {
          removeActivityFromReducer(this.state.popupActivityDetails);
        }
      }
    );
  }

  onArchiveItem = (activityDetails) => {
    const { archiveOrUnArchiveActivitiesAction } = this.props;
    let updatedActivity = { ...activityDetails };
    let user_id = parseInt(localStorage.getItem("id"));
    let currentActiveStatus =
      activityDetails.data.archivedUsers &&
      activityDetails.data.archivedUsers.includes(user_id);

    const customer_auth_token = localStorage.getItem(
      activities.customerAuthToken
    );

    let archiveData = {
      completed_on: moment.utc(new Date()).format(),
      data: {
        ...updatedActivity.data,
        lastModifiedBy: parseInt(user_id),
        archivedUsers: updatedActivity.data.archivedUsers
          ? [...updatedActivity.data.archivedUsers, user_id]
          : [user_id],
        priority: updatedActivity.data.priority
          ? updatedActivity.data.priority.filter(
              (user) => user.user_id !== user_id
            )
          : [],
      },
      version_id: updatedActivity.version_id,
      id: updatedActivity.id,
    };

    let unArchiveData = {
      data: {
        ...updatedActivity.data,
        archivedUsers: activityDetails.data.archivedUsers
          ? activityDetails.data.archivedUsers.filter(
              (user) => user !== user_id
            )
          : [],
      },
      version_id: updatedActivity.version_id,
      id: updatedActivity.id,
    };

    archiveOrUnArchiveActivitiesAction(
      {
        auth_token: customer_auth_token,
        archiveData: currentActiveStatus ? unArchiveData : archiveData,
        type: currentActiveStatus ? activities.unArchive : activities.archive,
      },
      (response) => {
        if (response && response.id)
          firebase.analytics().logEvent("GTD_Activity_archived");
      }
    );
  };

  renderActivities = () => {
    const {
      listOfActivity,
      unArchivedProjectList,
      archiveOrUnArchiveActivitiesAction,
      archiveOrUnarchiveLoaderState,
      prioritiZedActivity,
      changeWorkFlowAction,
      getFilteredResults,
      searchContent,
      handleClickEditCallback,
      router,
    } = this.props;
    let isFilterEnabled = searchContent.context === activities.active;
    let sortedActivity = null;
    let list = isFilterEnabled
      ? getFilteredResults(listOfActivity.list, searchContent.text)
      : listOfActivity.list;

    const sortParameter = this.state.sortByDeprioritized;
    switch (sortParameter.id) {
      case 1:
        sortedActivity = _.orderBy(list, function(e) {
          return e.title.toLowerCase();
        });
        break;

      case 2:
        sortedActivity = _.orderBy(list, function(e) {
          return e.project.name.toLowerCase();
        });
        break;

      case 3:
        sortedActivity = _.orderBy(list, "created_at").reverse();
        break;

      case 4:
        let sortedActivity1 = _.orderBy(list, ["due_on"], ["asc"]);
        sortedActivity = sortedActivity1.sort(function(a, b) {
          if (moment(a.due_on).isSame(b.due_on, "day")) {
            if (a.title.toLowerCase() < b.title.toLowerCase()) {
              return -1;
            }
            if (a.title.toLowerCase() > b.title.toLowerCase()) {
              return 1;
            }
            return 0;
          }
          return 0;
        });
        break;
      default:
        sortedActivity = listOfActivity;
    }
    const renderActivities = sortedActivity.map((activityDetails) => {
      if (!activityDetails.workflow) {
        activityDetails = {
          ...activityDetails,
          workflow: { id: 1, name: "Backlog" },
        };
      }
      return (
        <ActivityTile
          key={activityDetails.id}
          archiveOrUnArchiveActivitiesAction={
            archiveOrUnArchiveActivitiesAction
          }
          archiveOrUnarchiveLoaderState={archiveOrUnarchiveLoaderState}
          unArchivedProjectList={unArchivedProjectList}
          changeWorkFlowAction={changeWorkFlowAction}
          activityDetails={activityDetails}
          shouldShowIcons={{
            archive: true,
            edit: true,
            priority_empty: true,
            show_play_icon: true,
          }}
          prioritiZedActivity={prioritiZedActivity}
          handleShowPopup={() => this.handleShowPopup(activityDetails)}
          onArchiveItem={this.onArchiveItem}
          handleClickEditCallback={handleClickEditCallback}
          router={router}
          onShowCannotPrioritizePopup={this.onShowCannotPrioritizePopup}
        />
      );
    });

    return renderActivities;
  };

  onShowCannotPrioritizePopup = (res) => {
    this.setState({ showCannotPrioritizePopup: true, errorObj: res });
  };

  onHideCannotPrioritizePopup = () => {
    this.setState({ showCannotPrioritizePopup: false, errorObj: null });
  };

  // componentWillUnmount() {
  //   this.setState({});
  // }

  showMore = () => {
    const { getDeprioritizedActivitiesForWorkspace, page, searchContent } = this.props;
    this.setState(
      {
        page: this.state.page + 1,
      },
      () => {
        let auth_token = localStorage.getItem("customer_auth_token");
        let activity_type = "ACTIVITY";
        let is_active = true;
        let user_id = localStorage.getItem("id");
        let is_archived = false;
        let pageNo = page ? page + 1 : 1;
        let item_per_page = 50;
        getDeprioritizedActivitiesForWorkspace(
          {
            auth_token,
            activity_type,
            is_active,
            user_id,
            is_archived,
            page: pageNo,
            item_per_page,
          },
          (res) => {
            if (res) {
              if (searchContent.text) {
                setTimeout(() => {
                  var updatedContext = document.querySelectorAll(".act-title");
                  var updatedInstance = new Mark(updatedContext);
                  updatedInstance.unmark();
                  updatedInstance.mark(searchContent.text, {
                    element: "span",
                    className: "marked-search",
                    accuracy: "partially",
                  });
                });
              }
            }
          }
        );
      }
    );
  };

  render() {
    const {
      prioritiZedActivity,
      activeTab,
      searchContent,
      getFilteredResults,
      unArchivedProjectList,
      archiveOrUnArchiveActivitiesAction,
      archiveOrUnarchiveLoaderState,
      changeWorkFlowAction,
      startTimerAction,
      stopTimerAction,
      createTimerAction,
      getTimerStatusForWorkspace,
      timerRunningStatus,
      anyTimerStatus,
      changeTimerStatus,
      fetchTimesheetAction,
      handleClickCallback,
      handleClickEditCallback,
      listOfActivity,
      updateDeprioritizedActivitiesLoaderState,
      router,
      onWorkspaceNavBarClicked,
    } = this.props;
    const { showCannotPrioritizePopup, errorObj } = this.state;
    let isFilterEnabled = searchContent.context === activities.active;
    let list = isFilterEnabled
      ? getFilteredResults(prioritiZedActivity, searchContent.text)
      : prioritiZedActivity;
    const showPriorityCard = isFilterEnabled && list.length < 1 ? true : false;
    const showDeprioritizedCard =
      isFilterEnabled && this.renderActivities().length < 1 ? true : false;

    let dialogProps = {
      className: "activity-popup-container",
      showPopup: this.props.isPopupEnableInner,
      dialogTitle: () => { return },
      onCloseCallBack: this.onPopupCloseCallBack,
      shouldButtonEnable: true,
      shouldScrollUpdate: true,
    };

    let dialogPropsCannotPrioritize = {
      className: "dialog_wrapper",
      showPopup: showCannotPrioritizePopup,
      dialogTitle: () =>
        errorObj?.error === "Priority limit exceeded" && errorObj.error,
      onCloseCallBack: this.onHideCannotPrioritizePopup,
      shouldButtonEnable: true,
    };

    return (
      <div className="create-activity-scroll" id="create-activity-scroll">
        {this.props.isPopupEnableInner === true &&
          this.state.popupActivityDetails.id && (
            <DialogWrapper dialogProps={dialogProps}>
              <>
                <ActivityPopupTop
                  activityDetails={this.state.popupActivityDetails}
                  onClosePopup={dialogProps.onCloseCallBack}
                  onArchiveItem={() =>
                    this.onArchiveItem(
                      this.state.popupActivityDetails,
                      archiveOrUnArchiveActivitiesAction
                    )
                  }
                  source={
                    this.state.popupActivityDetails.data.archivedUsers &&
                    this.state.popupActivityDetails.data.archivedUsers.includes(
                      parseInt(localStorage.getItem("id"))
                    )
                      ? "FROM_ARCHIVE"
                      : "FROM_ACTIVE"
                  }
                  is_priority={this.state.is_priority}
                  changeWorkFlowAction={changeWorkFlowAction}
                  archiveOrUnArchiveActivitiesAction={
                    archiveOrUnArchiveActivitiesAction
                  }
                  archiveOrUnarchiveLoaderState={archiveOrUnarchiveLoaderState}
                  prioritiZedActivity={prioritiZedActivity}
                  handleClickEditCallback={handleClickEditCallback}
                  router={router}
                  onWorkspaceNavBarClicked={onWorkspaceNavBarClicked}
                />
                <ActivityPopupBottom
                  activityDetailsAsProps={this.state.popupActivityDetails}
                  is_archived={false}
                  is_priority={this.state.is_priority}
                  source="FROM_ACTIVE"
                  clickedType={
                    router.location.query.comment_id
                      ? "comments"
                      : router.location.query.checklist_id
                      ? "checklists"
                      : null
                  }
                  clickedId={
                    parseInt(router.location.query.comment_id) ||
                    parseInt(router.location.query.checklist_id) ||
                    null
                  }
                  checklistItemId={
                    parseInt(router.location.query.checklistItemId) || null
                  }
                  parentCommentId={
                    parseInt(router.location.query.parent_comment_id) ||
                    parseInt(router.location.query.comment_id) ||
                    null
                  }
                />
              </>
            </DialogWrapper>
          )}
        <div className="activity-column">
          {/* <div className="wl-filter-description not-found">
                    No results found
                </div> */}

          {!showPriorityCard && (
            <div className="margin-btw-rows" id="difference">
              <PriorityActivity
                archiveOrUnArchiveActivitiesAction={
                  archiveOrUnArchiveActivitiesAction
                }
                archiveOrUnarchiveLoaderState={archiveOrUnarchiveLoaderState}
                unArchivedProjectList={unArchivedProjectList}
                activeTab={activeTab}
                searchContent={searchContent}
                getFilteredResults={getFilteredResults}
                prioritiZedActivity={prioritiZedActivity}
                changeWorkFlowAction={changeWorkFlowAction}
                startTimerAction={startTimerAction}
                stopTimerAction={stopTimerAction}
                createTimerAction={createTimerAction}
                getTimerStatusForWorkspaceAction={getTimerStatusForWorkspace}
                timerRunningStatus={timerRunningStatus}
                anyTimerStatus={anyTimerStatus}
                changeTimerStatus={changeTimerStatus}
                fetchTimesheetAction={fetchTimesheetAction}
                handleShowPopup={this.handleShowPopup}
                handleClickCallback={handleClickCallback}
                handleClickEditCallback={handleClickEditCallback}
                onArchiveItem={this.onArchiveItem}
                router={router}
                onWorkspaceNavBarClicked={onWorkspaceNavBarClicked}
              />
            </div>
          )}
          {updateDeprioritizedActivitiesLoaderState.fetching && (
            <div className="nw-att-loading-holder">
              <Loader size={100} loading={true} />
            </div>
          )}
          {!showDeprioritizedCard && (
            <div className="rest-wrapper" id="backlogcards">
              {/* load */}

              {this.renderActivities().length > 0 && (
                <div className="dpheader">
                  <span className="Deprioritized-activities">
                    Deprioritized activities
                  </span>
                  {!isFilterEnabled && listOfActivity.list.length > 2 && (
                    <SelectField
                      dropDownOptions={this.dropDownOptions}
                      selectedOption={this.state.sortByDeprioritized}
                      onClickDropDownOptions={this.onClickDropDownOptions}
                    ></SelectField>
                  )}
                </div>
              )}
              {/* Infinite scroll add  */}
              <InfiniteScroll
                dataLength={listOfActivity.list.length}
                onScroll={() => {
                  firebase.analytics().logEvent("GTD_Activity_page_scroll");
                  this.setState({
                    isScrollable: true,
                  });
                }}
                next={() => {
                  this.showMore();
                }}
                hasMore={true}
                // style={{ width: "10px" }}
                scrollableTarget={"activity-container-scroll-div"}
              >
                <div className="rest-activity">
                  <div layout="column" className="scroll-activity">
                    {this.renderActivities()}
                  </div>
                </div>
              </InfiniteScroll>
            </div>
          )}

          {showPriorityCard && showDeprioritizedCard && (
            <div className="wl-filter-description not-found">
              <span>No Result Found</span>
            </div>
          )}
          {showCannotPrioritizePopup && (
            <DialogWrapper dialogProps={dialogPropsCannotPrioritize}>
              <TimerCannotStartPopup
                onClosePopup={this.onHideCannotPrioritizePopup}
                errorObj={errorObj}
              />
            </DialogWrapper>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCustomerForPeopleAction: (callback) => {
      dispatch(getAllCustomerForPeopleAction(callback));
    },
    getDeprioritizedActivitiesForWorkspace(
      {
        auth_token,
        activity_type,
        is_active,
        user_id,
        is_archived,
        page,
        item_per_page,
      },
      callback
    ) {
      dispatch(
        getDeprioritizedActivitiesForWorkspaceAction(
          {
            auth_token,
            activity_type,
            is_active,
            user_id,
            is_archived,
            page,
            item_per_page,
          },
          callback
        )
      );
    },
    getTimerStatusForWorkspace({ auth_token }, callback) {
      dispatch(
        getTimerStatusForWorkspaceAction({ auth_token }, callback)
      );
    },
    clearReducerAction: (callback) => {
      dispatch(clearReducerAction(callback));
    },
    getActivityById: ({ id, user_id }, callback) => {
      dispatch(getActivityByIdAction({ id, user_id }, callback));
    },
    getActivityByIdForNotificationAction: ({ id, user_id }, callback) => {
      dispatch(getActivityByIdForNotificationAction({ id, user_id }, callback));
    },
    removeActivityFromReducer: (activity) => {
      dispatch(removeActivityFromReducerAction(activity));
    },
  };
};
const mapStateToProps = (state) => {
  return {
    // 336:
    page: state.activityReducer.listOfActivity.page,
    archiveOrUnarchiveLoaderState:
      state.activityReducer.archiveOrUnarchiveLoaderState,
    unArchivedProjectList: state.projectReducer.listOfProjects.list,
    updateDeprioritizedActivitiesLoaderState:
      state.wrorkSpaceReducer.updateDeprioritizedActivitiesLoaderState,
    // listOfCustomer: state.wrorkSpaceReducer.listOfCustomer.list
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ActiveActivities);
