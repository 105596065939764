import React, { PureComponent } from "react";
import { Link } from "react-router";
import "../../styles/scss/navbar.scss";
import NavbarDrop from "./partials/navbardrop";
import _ from "lodash";
import moment from "moment";
import Avatar from "react-avatar";
import {
  getProfileDetails,
  updateLocalStorgae,
} from "../../helpers/generichelper";
import { getDefaultClientLogo } from "../../helpers/generichelper";
import routesHelper from "../../router/routeshelper";
import navBarActLogo from "../../styles/img/svg/navBar-activities.svg";
import navBarRepLogo from "../../styles/img/svg/navBar-report.svg";
import navBarWolLogo from "../../styles/img/svg/navBar-workload.svg";
import navBarProLogo from "../../styles/img/svg/navBar-project.svg";
import navBarDasLogo from "../../styles/img/svg/dashboard_board.svg";
import navBarLogo from "../../styles/img/svg/navBar-logo.svg";
import navBarArrowLogo from "../../styles/img/svg/navBar-arrow.svg";
import navBarClosedLogo from "../../styles/img/svg/navBar_closedLogo.svg";
import archiveLogo from "../../styles/img/svg/navbar-archive.svg";

import ReactTooltip from "react-tooltip";

import { reports } from "../../constants/reportsconstants";
import { getIsSuperAdmin } from "../../helpers/reportlanding";
import firebase from "../../firebase";
import { activities } from "../../constants/activitiescontants";
import { switchWorkspace } from "../../services/workspaceapi";
export default class SidebarNav extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeDropDown: false,
      navbarState:
        localStorage.getItem("expandedNavbar") === "true" ? true : false,
      selectedOption: "Activities",
    };

    this.onNavbarWorkspaceClick = this.onNavbarWorkspaceClick.bind(this);
    this.onWorkspaceNavBarClicked = this.onWorkspaceNavBarClicked.bind(this);
    this.toggleNavbar = this.toggleNavbar.bind(this);
  }

  onNavbarWorkspaceClick(_e) {
    firebase.analytics().logEvent("GTD_Settings_dropdown");
    this.setState({
      activeDropDown: !this.state.activeDropDown,
    });
  }
  handleClickOutside = event => {
    if (this.outerDivNode && !this.outerDivNode.contains(event.target)) {
      this.setState({
        activeDropDown: false,
      });
    }
  };

  onWorkspaceNavBarClicked(_e, customer, routePath) {
    const user_id = localStorage.getItem("id");
    const {
      getUserDetailsAction,
      updateSelectedWorkpaceDetailsAction,
      getWorkspaceUsers,
      getCustomerInfo,
      // getSelectedWorkpsaceActivities,
      updateUserProfileAction,
      clearReducerWithPrioritizeAction,
      clearTimesheetAction,
      clearDashboardReducerAction,
      clearArchivedChecklistAction,
      currentUser,
      updateLoginAuth,
      getProjectsAction,
      updateTabState,
      updateDashboardTabState,
      // totalActvityCountAction,
      // fetchTimeSheetForReport,
      updateReportFilterAction,
      updateReportSortAction,
      // getSelectedWorkpsaceArchivedActivities,
      // getArchivedProjectsAction,
      clearProjectsAction,
      // getChecklistAction,
      // getArchivedChecklistAction,
      fetchTimesheetAction,
      getSystemNotificationsAction,
      getAllNotificationsAction,
      getYourNotificationsAction,
      // getTimerStatusForWorkspaceAction,
      getPrioritizedActivitiesForWorkspaceAction,
      getDeprioritizedActivitiesForWorkspaceAction,
    } = this.props;
    // currentUser.data.last_worked_workspace = customer.id;
    //Close workspace dropdown
    this.setState({
      activeDropDown: false,
    });

    getUserDetailsAction(
      { queryparams: `?id=${parseInt(localStorage.getItem("id"))}` },
      res => {
        if (res && !res.error && !res.stdErr && res.length > 0) {
          let user = res[0];
          user = _.omit(user, ["auth_token"]);
          user.id = parseInt(user.id);
          if (!user.img) user = _.omit(user, ["img"]);
          if (!user.country) user = _.omit(user, ["country"]);
          if (!user.organization_name)
            user = _.omit(user, ["organization_name"]);
          user.data = {
            ...user.data,
            last_worked_workspace: customer.id,
          };
          updateUserProfileAction(user, response => {
            firebase.analytics().logEvent("GTD_Workspace_Switch_Workspace");
            switchWorkspace({ customer_id: customer.id }, switchRes => {
              if (switchRes && !switchRes.hasOwnProperty("stdErr")) {
                // updates local storage
                updateLocalStorgae(response);
                // update Login Auth reducer
                updateLoginAuth({
                  ...currentUser,
                  ...response,
                  data: response.data,
                });

                // update selected workspace : reducer
                updateSelectedWorkpaceDetailsAction(customer);

                getWorkspaceUsers({}, response => {
                  if (response && !response.stdErr) {
                    // TODO document why this block is empty
                  
                  }
                });

                getCustomerInfo(_response => { /* TODO document why this arrow function is empty */ });

                getPrioritizedActivitiesForWorkspaceAction(
                  {
                    activity_type: "ACTIVITY",
                    auth_token: customer.auth_token,
                    user_id,
                    is_active: true,
                    is_archived: false,
                  },
                  _res => { /* TODO document why this arrow function is empty */ }
                );

                getDeprioritizedActivitiesForWorkspaceAction(
                  {
                    activity_type: "ACTIVITY",
                    auth_token: customer.auth_token,
                    user_id,
                    is_active: true,
                    is_archived: false,
                    page: 1,
                    item_per_page: 50,
                  },
                  _res => { /* TODO document why this arrow function is empty */ }
                );

                // get total Activity Count
                // totalActvityCountAction({
                //   activity_type: "ACTIVITY",
                //   user_id: currentUser.id,
                //   auth_token: currentUser.auth_token
                // });

                // get Total Project
                // getProjectsAction(
                //   {
                //     auth_token: customer.auth_token,
                //     queryParam: "?archived=false"
                //   },
                //   response => {
                //     if (response && response.length > 0) {
                //     }
                //   }
                // );

                // Fetch TimeSheet for Report
                // fetchTimeSheetForReport();

                // Update Report filter/sort options
                updateReportFilterAction({
                  projectId: "all",
                  selectedProject: "All",
                  memberId: currentUser.id,
                  selectedMember: currentUser.first_name,
                  from: moment(
                    new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000)
                  ).format("ddd, DD MMM YY"),
                  to: moment(new Date()).format("ddd, DD MMM YY"),
                  showResetFilter: false,
                  activityType: "All",
                });
                updateReportSortAction({
                  sortBy: reports.date,
                  sortType: false,
                });

                // get Archived activities of Workspace
                // getSelectedWorkpsaceArchivedActivities(
                //   {
                //     auth_token: customer.auth_token,
                //     user_id,
                //     activity_type: "ACTIVITY",
                //     is_archived: true,
                //     is_active: true,
                //     page: 1,
                //     item_per_page: 50
                //   },
                //   (response) => {
                //     // write what to do on success
                //   }
                // );

                // get Unarchived Project
                let queryParamUnarchieved = getIsSuperAdmin(
                  currentUser,
                  customer
                )
                  ? `?archived=false`
                  : `?user_id=${parseInt(currentUser.id)}&archived=false`;
                getProjectsAction(
                  {
                    auth_token: customer.auth_token,
                    queryParam: queryParamUnarchieved,
                  },
                  _response => { /* TODO document why this arrow function is empty */ }
                );

                // change tab state back to "Active"
                updateTabState({ projectsLanding: "ACTIVE" });

                // change dashboard tab state back to "Team"
                updateDashboardTabState({
                  dashboardLanding: getIsSuperAdmin(currentUser, customer)
                    ? "Team"
                    : "Personal",
                });

                // Get Archived Projects
                // let queryParam = getIsSuperAdmin(currentUser, customer)
                //   ? `?archived=true`
                //   : `?user_id=${parseInt(user_id)}&archived=true`;
                // getArchivedProjectsAction(
                //   {
                //     auth_token: customer.auth_token,
                //     queryParam
                //   },
                //   (response) => {}
                // );

                // Get Unarchived Checklist
                // const checklistParam = `?user_id=${parseInt(user_id)}&is_archived=false`;
                // getChecklistAction(
                //   {
                //     queryParams: checklistParam
                //   },
                //   (response) => {
                //     // console.log(response);
                //   }
                // );

                // Get archived Checklist
                // const checklistArchivedParam = `?user_id=${parseInt(
                //   user_id
                // )}&is_archived=true`;

                

                // Fetch today's total worked hours for workspace
                fetchTimesheetAction(
                  {
                    from_date: moment().startOf("day").format("YYYY-MM-DD"),
                    to_date: moment().format("YYYY-MM-DD"),
                    user_id,
                  },
                  _response => {
                    // TODO document why this arrow function is empty
                  
                    
                  }
                );

                // notification
                getSystemNotificationsAction({
                  auth_token: customer.auth_token,
                });
                let page = 1;
                let per_page = 10;
                let recipient_id = localStorage.getItem("id");
                getAllNotificationsAction(
                  {
                    auth_token: customer.auth_token,
                    page,
                    per_page,
                    recipient_id,
                  },
                  () => { /* TODO document why this arrow function is empty */ }
                );
                getYourNotificationsAction({
                  auth_token: customer.auth_token,
                  page,
                  per_page,
                  recipient_id,
                });

                // Clear Prioritized & Deprioritized activities of previous workspace
                clearReducerWithPrioritizeAction();

                //Clear Reports of previous workspace
                clearTimesheetAction();

                // Clear Projects of previous workspace
                clearProjectsAction();

                // Clear Dashboard data of previous workspace
                clearDashboardReducerAction();

                // Clear Archived checklist of previous workspace
                clearArchivedChecklistAction();

                // Goto activity page after changing workspace
                this.props.router.push(routesHelper.getActivityPath());
                this.setState({ selectedOption: "Activities" });

                //Close workspace dropdown
                // this.setState({
                //   activeDropDown: false,
                // });
              }
            });
          });
        }
      }
    );
  }

  toggleNavbar() {
    firebase.analytics().logEvent("GTD_Navigation_toggle");
    this.setState(
      {
        navbarState: !this.state.navbarState,
      },
      () => {
        document.querySelector(".forward-arrow").style.display = "none";
        if (this.state.navbarState) {
          document.querySelector(".navBar-closedLogo").style.display = "block";
          document.querySelector(".navBar-closedLogo").style.marginBottom =
            "15px";
          document.querySelector(".navBar-closedLogo").style.opacity = "0.5";
          document.querySelector(".navBar-logo").style.display = "none";
        }
        setTimeout(() => {
          document
            .querySelector(".forward-arrow")
            .style.removeProperty("display");
          document
            .querySelector(".navBar-closedLogo")
            .style.removeProperty("display");
          document
            .querySelector(".navBar-closedLogo")
            .style.removeProperty("margin-bottom");
          document
            .querySelector(".navBar-closedLogo")
            .style.removeProperty("opacity");
          document
            .querySelector(".navBar-logo")
            .style.removeProperty("display");
        }, 300);
      }
    );
    
    const { expandNavBar, navbarState } = this.props;
    localStorage.setItem("expandedNavbar", !navbarState);
    expandNavBar(!navbarState);
  }

  onSelectNavBarOption = (_event, selectedOption) => {
    
    firebase
      .analytics()
      .logEvent("GTD_Navigation_other_pages", { title: selectedOption });
    
    this.setState({ selectedOption });
  };

  UNSAFE_componentWillMount() {
    document.addEventListener("mousedown", this.handleClickOutside, false);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside, false);
  }

  render() {
    const { activeDropDown } = this.state;
    const currentUser = getProfileDetails();
    const {
      listOfCustomer,
      selectedCustomerDetails,
      router,
      navbarState,
      currentUser: currUser,
    } = this.props;
    const selectedWorkspace = selectedCustomerDetails || {};
    
    return (
      <React.Fragment>
        <section
          data-tut="navBar-container"
          className={
            navbarState ? "navBar-container" : "navBar-container closed-navBar"
          }
        >
          {!navbarState && (
            <ReactTooltip
              place="bottom"
              type="dark"
              effect="solid"
              className="general-tooltip"
            />
          )}

          <div
            className={"navBar-workspace-container"}
            ref={node => (this.outerDivNode = node)}
          >
            <div
              data-tut="navBar-workspace-holder"
              className="navBar-workspace-holder"
              onClick={this.onNavbarWorkspaceClick}
            >
              <div
                className={
                  activeDropDown
                    ? "navBar-workspace-img active-close-dropDown"
                    : "navBar-workspace-img"
                }
              >
                {selectedWorkspace.img &&
                  selectedWorkspace.img !==
                    "//s3.amazonaws.com/gtd/gtd-placeholder.png" &&
                  selectedWorkspace.img !== getDefaultClientLogo() && (
                    <img src={selectedWorkspace.img} alt="wp-logo" />
                  )}
                {selectedWorkspace.id &&
                  (!selectedWorkspace.img ||
                    selectedWorkspace.img ===
                      "//s3.amazonaws.com/gtd/gtd-placeholder.png" ||
                    selectedWorkspace.img === getDefaultClientLogo()) && (
                    <Avatar
                      // style={{ width: "100%", height: "100%" }}
                      name={selectedWorkspace.display_name}
                      maxInitials={1}
                    ></Avatar>
                  )}
                {!selectedWorkspace.id &&
                  localStorage.getItem(activities.customerImage) &&
                  localStorage.getItem(activities.customerImage) !==
                    "//s3.amazonaws.com/gtd/gtd-placeholder.png" &&
                  localStorage.getItem(activities.customerImage) !==
                    getDefaultClientLogo() && (
                    <img
                      src={localStorage.getItem(activities.customerImage)}
                      alt="wp-logo"
                    />
                  )}
                {!selectedWorkspace.id &&
                  (!localStorage.getItem(activities.customerImage) ||
                    localStorage.getItem(activities.customerImage) ===
                      "//s3.amazonaws.com/gtd/gtd-placeholder.png" ||
                    localStorage.getItem(activities.customerImage) ===
                      getDefaultClientLogo()) && (
                    <Avatar
                      // style={{ width: "100%", height: "100%" }}
                      name={localStorage.getItem(
                        activities.customerDisplayName
                      )}
                      maxInitials={1}
                    ></Avatar>
                  )}
              </div>
              <p
                className={
                  activeDropDown
                    ? "navBar-workspace-title active-dropDown"
                    : "navBar-workspace-title"
                }
              >
                {" "}
                {selectedWorkspace.display_name ||
                  localStorage.getItem(activities.customerDisplayName) ||
                  ""}{" "}
              </p>
            </div>
            {activeDropDown && (
              <NavbarDrop
                // currentUser={currUser}
                listOfCustomer={listOfCustomer}
                selectedWorkspace={selectedWorkspace}
                currentUser={currentUser || currUser}
                onWorkspaceNavBarClicked={this.onWorkspaceNavBarClicked}
                router={router}
              ></NavbarDrop>
            )}
          </div>
          <div className="navBar-options-container">
            <Link
              to={routesHelper.getActivityPath()}
              className={
                router.routes[0].path === routesHelper.getActivityPath()
                  ? "navBar-options active"
                  : "navBar-options"
              }
              onClick={event => this.onSelectNavBarOption(event, "Activities")}
            >
              <span className="navBar-img-options">
                <img
                  src={navBarActLogo}
                  data-tip="Activities"
                  alt="activity-icon"
                />
              </span>
              <span className="navBar-title"> Activities</span>
            </Link>
            <Link
              to={routesHelper.getReportPath()}
              className={
                router.routes[0].path === routesHelper.getReportPath()
                  ? "navBar-options active"
                  : "navBar-options"
              }
              onClick={event => this.onSelectNavBarOption(event, "Report")}
            >
              <span className="navBar-img-options-report">
                <img src={navBarRepLogo} data-tip="Report" alt="report-icon" />
              </span>
              <span className="navBar-title"> Report</span>
            </Link>
            {/* {isUserAdmin && ( */}
            <Link
              to={routesHelper.workloadPath()}
              className={
                router.routes[0].path === routesHelper.workloadPath()
                  ? "navBar-options active"
                  : "navBar-options"
              }
              onClick={event => this.onSelectNavBarOption(event, "Workload")}
            >
              {!navbarState && (
                <ReactTooltip
                  place="bottom"
                  type="dark"
                  effect="solid"
                  className="general-tooltip"
                />
              )}
              <span className="navBar-img-options-workload">
                <img
                  src={navBarWolLogo}
                  data-tip="Workload"
                  alt="workload-icon"
                />
              </span>
              <span className="navBar-title"> Workload</span>
            </Link>

            <Link
              to={routesHelper.getProjectsPath()}
              className={
                router.routes[0].path === routesHelper.getProjectsPath()
                  ? "navBar-options active"
                  : "navBar-options"
              }
              onClick={event => this.onSelectNavBarOption(event, "Projects")}
            >
              <span className="navBar-img-options">
                <img
                  src={navBarProLogo}
                  data-tip="Projects"
                  alt="projects-icon"
                />
              </span>
              <span className="navBar-title"> Projects</span>
            </Link>
            <Link
              to={routesHelper.getDashboardPath()}
              className={
                router.routes[0].path === routesHelper.getDashboardPath()
                  ? "navBar-options active"
                  : "navBar-options"
              }
              onClick={event => this.onSelectNavBarOption(event, "Dashboard")}
            >
              {!navbarState && (
                <ReactTooltip
                  place="bottom"
                  type="dark"
                  effect="solid"
                  className="general-tooltip"
                />
              )}
              <span className="navBar-img-options">
                <img src={navBarDasLogo} data-tip="Dashboard" alt="dash-icon" />
              </span>
              <span className="navBar-title"> Dashboard</span>
            </Link>
            <Link
              to={routesHelper.getArchivedPath()}
              className={
                router.routes[0].path === routesHelper.getArchivedPath()
                  ? "navBar-options active"
                  : "navBar-options"
              }
              onClick={event => this.onSelectNavBarOption(event, "Archived")}
            >
              <span className="navBar-img-options-archive">
                <img src={archiveLogo} data-tip="Archive" alt="archive-icon" />
              </span>
              <span className="navBar-title"> Archive</span>
            </Link>
          </div>
          <div className="navBar-options-footer">
            <div
              className="forward-arrow"
              role="button"
              onClick={this.toggleNavbar}
              tabIndex="0"
            >
              <img src={navBarArrowLogo} alt="nav-arrow" />
            </div>
            <div
              className="navBar-logo"
              onClick={() =>
                firebase.analytics().logEvent("GTD_App_bottom_logo")
              }
            >
              <a
                href={routesHelper.getGtdLandingUri()}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={navBarLogo} alt="gtd-logo" />
              </a>
              <div className="version-no">
                version {process.env.REACT_APP_GTD_VERSION}
              </div>
            </div>
            <div
              className="navBar-closedLogo"
              onClick={() =>
                firebase.analytics().logEvent("GTD_App_bottom_logo")
              }
            >
              <a
                href={routesHelper.getGtdLandingUri()}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={navBarClosedLogo} alt="gtd-logo" />
              </a>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
