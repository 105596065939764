import React, { PureComponent } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { toast } from "react-toastify";
import OutsideClickHandler from "react-outside-click-handler";
import * as action from "../../actions/index";
import TextareaAutosize from "react-textarea-autosize";
// import ChipInput from "material-ui-chip-input";
// import { Loader } from "../common/loader";
import addLogo from "../../styles/img/svg/Add_Logo_Icon.svg";
// import downArrow from "../../styles/img/svg/settings-dropdown-arrow.svg";
import "../../styles/scss/peopleSettings.scss";
import LogoUploader from "../common/logoUploader";
import DateRangePicker from "../common/dateRangePicker";
import StatusDropDown from "./statusDropdown";

import "../../styles/scss/activityPopupTop.scss";

// import GroupDropDown from "./groupDropDown";
import {
  getStatusView,
  getProjectObjToUpdate,
} from "../../helpers/projectsettings";
import InputChips from "./inputChips";
import EngagementDropDown from "./engagementDropDown";
import firebase from "../../firebase";
import "../../styles/scss/organization.scss";
import { getProjectById } from "../../services/projectapi";

class ProjectSettingsForm extends PureComponent {
  state = {
    imageLoading: false,
    clientLogo: null,
    img_name: null,
    showGroupDropDown: false,
    showAllGroupsDropDown: false,
    showStatusDropDown: false,
    showEngagementDropDown: false,
    filteredGroupList: [],
    selectedStatus: "",
    selectedStatusView: null,
    name: "",
    group: "",
    start: "",
    due: "",
    estimated_hours: "",
    budget: "",
    engagement: "",
    description: "",
    activity_type: [],
    kanban_list: [],
    workflow: {},
    workflowOrder: [],
    workflow_changes: { added: [], archived: [], renamed: [], unarchived: [] },
    showArchive: false,
    allProjectActivityType: [],
    selectedActivityType: [],
    notUpdatePrev: true,
    previousProjectId: "",
  };

  handleOnChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleGroupOnChange = event => {
    this.setState({ group: event.target.value });
    const pattern = `^${event.target.value}`;
    const regex = new RegExp(pattern, "i");
    const filteredGroupList = [];
    this.props.groupList.forEach(group => {
      if (regex.test(group)) {
        filteredGroupList.push(group);
      }
    });
    if (filteredGroupList.length > 0)
      this.setState({ filteredGroupList, showGroupDropDown: true });
    else this.setState({ showGroupDropDown: false });
  };

  updateLogo = (clientLogo, img_name) => {
    firebase.analytics().logEvent("GTD_Project_Add_Image");
    this.setState({ clientLogo, img_name });
  };

  onShowStatusDropDown = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showStatusDropDown: !this.state.showStatusDropDown });
  };

  onOutsideClickStatus = e => {
    if (
      this.outerDivStatusNode &&
      !this.outerDivStatusNode.contains(e.target)
    ) {
      // console.log("I am clicked other way");
      if (this.state.showStatusDropDown) {
        this.setState({
          showStatusDropDown: false,
        });
      }
    }
    if (this.EnagementNode && !this.EnagementNode.contains(e.target)) {
      if (this.state.showEngagementDropDown) {
        this.setState({
          showEngagementDropDown: false,
        });
      }
    }
  };

  onShowGroupDropDown = () => {
    this.setState({ showGroupDropDown: false });
  };

  onFocusGroup = e => {
    this.setState({ showGroupDropDown: true, showAllGroupsDropDown: false });
  };

  onShowAllGroups = () => {
    this.setState({
      showGroupDropDown: !this.state.showGroupDropDown,
      showAllGroupsDropDown: true,
    });
  };

  onBlurGroup = event => {
    this.setState({ showGroupDropDown: false });
  };

  onSelectGroup = group => {
    let filterByGroup = [];
    const pattern = `^${group}`;
    const regex = new RegExp(pattern, "i");
    this.props.groupList.forEach(group => {
      if (regex.test(group)) {
        filterByGroup.push(group);
      }
    });
    this.setState({
      group,
      showGroupDropDown: false,
      filteredGroupList: filterByGroup,
    });
  };

  onSelectValue = (selectedStatus, selectedStatusView) => {
    firebase.analytics().logEvent("GTD_Project_Status_Changed");
    this.setState({ selectedStatus, selectedStatusView });
  };

  onSelectEngagementType = engagementType => {
    firebase
      .analytics()
      .logEvent("GTD_Project_Engagement_Type", { title: engagementType });
    this.setState({
      engagement: engagementType,
      showEngagementDropDown: false,
    });
  };

  onChangeDate = (event, picker) => {
    document.querySelector(
      ".daterangepicker .drp-selected"
    ).innerHTML = `${moment(this.state.start).format(
      "ddd, DD MMM, YYYY"
    )} - ${moment(this.state.due).format("ddd, DD MMM, YYYY")}`;
    const start = moment(picker.startDate._d)
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ");
    const due = moment(picker.endDate._d)
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ");
    this.setState({ start, due });
  };

  handleAddActivity = ({ value, addButton, indexvalue }) => {
    const { activity_type } = this.state;
    if (addButton) {
      this.setState({
        activity_type: _.sortBy(
          [...activity_type, value],
          [type => type.toLowerCase()],
          ["asc"]
        ),
      });
    } else {
      let activityTypeCopy = [...activity_type];
      activityTypeCopy[indexvalue] = value;
      this.setState({
        activity_type: _.sortBy(
          [...activityTypeCopy],
          [type => type.toLowerCase()],
          ["asc"]
        ),
      });
    }
  };

  handleDeleteActivity = (chip, index) => {
    const { activity_type } = this.state;
    this.setState({
      activity_type: _.sortBy(
        [
          ...activity_type.slice(0, index),
          ...activity_type.slice(index + 1, activity_type.length),
        ],
        [type => type.toLowerCase()],
        ["asc"]
      ),
    });
  };

  handleAddKanban = ({ value, addButton, indexvalue, oldValue }) => {
    // console.log("value,addButton,indexvalue,oldValue", value,addButton,indexvalue,oldValue)
    const {
      kanban_list,
      workflow,
      workflowOrder,
      workflow_changes,
    } = this.state;
    const { projectDetails } = this.props;
    let workFlowCopy = _.cloneDeep(workflow);
    let updatedWorkflowOrderCopy = _.cloneDeep(workflowOrder);
    let kanBanListCopy = _.cloneDeep(kanban_list);
    let workflow_changes_copy = _.cloneDeep(workflow_changes);
    if (!addButton) {
      if (workflow_changes_copy.added.includes(oldValue)) {
        workflow_changes_copy.added.splice(
          workflow_changes_copy.added.indexOf(oldValue),
          1,
          value
        );
      } else if (projectDetails.data.workflowOrder) {
        for (let workFlowEachOrder of projectDetails.data.workflowOrder) {
          if (workFlowEachOrder.name === oldValue) {
            workflow_changes_copy.renamed.push({
              id: workFlowEachOrder.id,
              old: oldValue,
              new: value,
            });
            break;
          } else {
            const alreadyRenamed = workflow_changes_copy.renamed.filter(
              wf => wf.id === workFlowEachOrder.id && oldValue === wf.new
            );
            // console.log("in else", alreadyRenamed)
            if (alreadyRenamed.length > 0) {
              let updatedRenamed = workflow_changes_copy.renamed.map(wf => {
                if (wf.id === workFlowEachOrder.id)
                  return { ...wf, new: value };
                return wf;
              });
              workflow_changes_copy = {
                ...workflow_changes_copy,
                renamed: updatedRenamed,
              };
              break;
            }
          }
        }
      } else if (projectDetails.workflow) {
        _.forIn(
          projectDetails.workflow,
          (workflowEachValue, workFlowEachKey) => {
            if (workflowEachValue.name === oldValue) {
              workflow_changes_copy.renamed.push({
                id: workFlowEachKey,
                old: oldValue,
                new: value,
              });
            } else {
              const alreadyRenamed = workflow_changes_copy.renamed.filter(
                wf => wf.id === workFlowEachKey && oldValue === wf.new
              );
              console.log("in else", alreadyRenamed);
              if (alreadyRenamed.length > 0) {
                let updatedRenamed = workflow_changes_copy.renamed.map(wf => {
                  if (wf.id === workFlowEachKey) return { ...wf, new: value };
                  return wf;
                });
                workflow_changes_copy = {
                  ...workflow_changes_copy,
                  renamed: updatedRenamed,
                };
              }
            }
          }
        );
      }

      for (let key in workFlowCopy) {
        if (workFlowCopy[key]["name"] === oldValue) {
          workFlowCopy[key]["name"] = value;
          break;
        }
      }
      for (let workFlowEachOrder of updatedWorkflowOrderCopy) {
        if (workFlowEachOrder.name === oldValue) {
          workFlowEachOrder.name = value;
          break;
        }
      }
      kanBanListCopy[indexvalue] = value;
    } else {
      let id = Object.keys(workflow).length + 1;
      kanBanListCopy.push(value);
      workFlowCopy[id] = {
        name: value,
        is_collapsed: false,
        is_archived: false,
      };
      updatedWorkflowOrderCopy.push({
        id,
        is_collapsed: false,
        is_archived: false,
        name: value,
      });
      let toUnarchive;
      _.forIn(projectDetails.workflow, (workflowEachValue, workFlowEachKey) => {
        if (workflowEachValue.name === value && workflowEachValue.is_archived)
          toUnarchive = { id: workFlowEachKey, name: value };
      });
      workflow_changes_copy = {
        ...workflow_changes,
        added:
          kanban_list.includes(value) ||
          workflow_changes.renamed.some(val => val.new === value) ||
          toUnarchive
            ? [...workflow_changes.added]
            : [...workflow_changes.added, value],
        archived: workflow_changes.archived.filter(
          workflow => workflow.name !== value
        ),
        unarchived: toUnarchive
          ? [...workflow_changes.unarchived, toUnarchive]
          : workflow_changes.unarchived,
      };
    }
    this.setState({
      kanban_list: kanBanListCopy,
      workflow: workFlowCopy,
      workflowOrder: updatedWorkflowOrderCopy,
      workflow_changes: workflow_changes_copy,
      // {
      //   ...workflow_changes,
      //   added: projectDetails.data.settings.kanban_list.includes(value) ?
      //     workflow_changes?.added ? [ ...workflow_changes.added ] : []
      //     :
      //     workflow_changes?.added ? [ ...workflow_changes.added, value ] : [ value ],
      //   archived: workflow_changes?.archived ? workflow_changes.archived.filter(workflow => workflow.name !== value) : []
      // }
    });
  };

  handleDeleteKanban = (value, index, type, callback, wfId) => {
    const {
      kanban_list,
      workflow,
      workflowOrder,
      workflow_changes,
    } = this.state;
    const { projectDetails } = this.props;
    // console.log("delete kanban list", value, index)
    let workFlowCopy = _.cloneDeep(workflow);
    let kanBanListCopy = _.cloneDeep(kanban_list);
    let workflowArchivedChanges = _.cloneDeep(workflow_changes.archived);

    for (const key in workFlowCopy) {
      if (workflow[key].name === value) {
        workFlowCopy[key].is_archived = true;
      }
    }
    let updatedWorkflowOrder = workflowOrder.map(workflow => {
      if (workflow.name === value) {
        return {
          ...workflow,
          is_archived: true,
        };
      }
      return workflow;
    });

    kanBanListCopy.splice(index, 1);
    if (!projectDetails.data.hasOwnProperty("workflowOrder")) {
      _.forIn(
        { ...projectDetails.workflow, ...workflow },
        (workflowEachValue, workFlowEachKey) => {
          if (workflowEachValue.name === value || workFlowEachKey === wfId)
            workflowArchivedChanges.push({
              id: workFlowEachKey,
              name: workflowEachValue.name,
            });
        }
      );
    }
    this.setState({
      kanban_list: kanBanListCopy,
      workflow: workFlowCopy,
      workflowOrder:
        updatedWorkflowOrder.length > 0 ? updatedWorkflowOrder : [],
      workflow_changes: {
        ...workflow_changes,
        added: workflow_changes.added.filter(newList => newList !== value),
        renamed: workflow_changes.renamed.filter(rm => rm.new !== value),
        unarchived: workflow_changes.unarchived.filter(wf => wf.id !== wfId),
        archived: workflow_changes.added.includes(value)
          ? workflow_changes.archived
          : projectDetails.data.hasOwnProperty("workflowOrder")
          ? _.uniqBy(
              [
                ...workflow_changes.archived,
                ...[...projectDetails.data.workflowOrder, ...workflowOrder]
                  .filter(
                    workflow => workflow.id === wfId || workflow.name === value
                  )
                  .map(workflow => {
                    return { id: workflow.id, name: workflow.name };
                  }),
              ],
              ["id", "name"]
            )
          : _.uniqBy(workflowArchivedChanges, "id"),

        // :
        //   [...projectDetails.data.workflowOrder, ...workflowOrder]
        //   .filter(workflow => workflow.name === value)
        //   .map(workflow => { return { id: workflow.id, name: workflow.name } })
      },
    });
  };

  updateProject = () => {
    const { projectDetails, updateProjectAction, updateList } = this.props;
    getProjectById(projectDetails.archived, projectDetails.id, response => {
      const body = getProjectObjToUpdate(
        this.state,
        response?.length > 0 ? response[0] : projectDetails
      );
      this.setState({ notUpdatePrev: false }, () => {
        updateProjectAction({ queryParam: "", body }, response => {
          if (response && response.id) {
            updateList && updateList(response);
            this.props.updateAcitvityType(
              response.data.settings.activity_type,
              response.id
            );
            toast("Changes saved successfully!");
          }
          this.setState({
            notUpdatePrev: true,
            workflow_changes: {
              added: [],
              archived: [],
              renamed: [],
              unarchived: [],
            },
          });
        });
      });
    });
  };

  componentDidMount() {
    const { edit, projectDetails, groupList } = this.props;
    if (edit === "edit" && projectDetails && groupList) {
      let {
        name,
        group,
        img,
        img_name,
        status,
        workflow,
        data: { start, due, settings, workflowOrder },
      } = projectDetails;

      let filterByGroup = [];
      if (group) {
        const pattern = `^${group}`;
        const regex = new RegExp(pattern, "i");
        groupList.forEach(group => {
          if (regex.test(group)) {
            filterByGroup.push(group);
          }
        });
      }
      if (!group) {
        filterByGroup = [...groupList];
        group = "";
      }
      let kanban_list = [];
      for (const key in workflow) {
        if (!workflow[key].is_archived) kanban_list.push(workflow[key]["name"]);
      }
      const { budget, engagement_type, description, activity_type } = settings;
      let estimated_hours = "";
      if (settings.hasOwnProperty("planned_hours")) {
        if (settings.planned_hours === 0) estimated_hours = "";
        else estimated_hours = settings.planned_hours;
      } else if (settings.hasOwnProperty("estimated_hours")) {
        if (settings.estimated_hours === 0) estimated_hours = "";
        else estimated_hours = settings.estimated_hours;
      } else estimated_hours = "";
      this.setState({
        clientLogo: img,
        img_name,
        selectedStatusView: null,
        name,
        group,
        filteredGroupList: filterByGroup,
        selectedStatus: status,
        start,
        due,
        estimated_hours,
        budget,
        engagement: engagement_type ? engagement_type : "",
        description: description ? description : "",
        activity_type: activity_type
          ? _.sortBy(activity_type, [type => type.toLowerCase()], ["asc"])
          : [],
        kanban_list,
        workflow,
        workflowOrder: workflowOrder ? workflowOrder : [],
        showSuccess: false,
        allProjectActivityType: this.props.allProjectActivityType,
        selectedActivityType: activity_type
          ? _.sortBy(activity_type, [type => type.toLowerCase()], ["asc"])
          : [],
        previousProjectId: projectDetails.id,
      });
    }
  }

  componentDidUpdate() {
    if (document.querySelector(".drp-selected")) {
      document.querySelector(".drp-selected").innerHTML = `${moment(
        this.state.start
      ).format("ddd, DD MMM, YYYY")} - ${moment(this.state.due).format(
        "ddd, DD MMM, YYYY"
      )}`;
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      newProps.projectDetails &&
      newProps.groupList &&
      this.state.notUpdatePrev
    ) {
      let {
        name,
        img,
        img_name,
        group,
        status,
        workflow,
        data: { start, due, settings, workflowOrder },
      } = newProps.projectDetails;

      let filterByGroup = [];
      if (group) {
        const pattern = `^${group}`;
        const regex = new RegExp(pattern, "i");
        newProps.groupList.forEach(group => {
          if (regex.test(group)) {
            filterByGroup.push(group);
          }
        });
      }
      if (!group) {
        filterByGroup = [...newProps.groupList];
        group = "";
      }
      let kanban_list = [];
      for (const key in workflow) {
        if (!workflow[key].is_archived) kanban_list.push(workflow[key]["name"]);
      }
      const { budget, engagement_type, description, activity_type } = settings;
      let estimated_hours = "";
      if (settings.hasOwnProperty("planned_hours")) {
        if (settings.planned_hours === 0) estimated_hours = "";
        else estimated_hours = settings.planned_hours;
      } else if (settings.hasOwnProperty("estimated_hours")) {
        if (settings.estimated_hours === 0) estimated_hours = "";
        else estimated_hours = settings.estimated_hours;
      } else estimated_hours = "";
      this.setState({
        clientLogo: img,
        img_name,
        selectedStatusView: null,
        name,
        group,
        filteredGroupList: filterByGroup,
        selectedStatus: status,
        start,
        due,
        estimated_hours,
        budget,
        engagement: engagement_type ? engagement_type : "",
        description: description ? description : "",
        kanban_list,
        workflow,
        activity_type: activity_type
          ? _.sortBy(activity_type, [type => type.toLowerCase()], ["asc"])
          : [],
        workflowOrder: workflowOrder ? workflowOrder : [],
        workflow_changes: {
          added: [],
          archived: [],
          renamed: [],
          unarchived: [],
        },
        showSuccess: false,
        allProjectActivityType: newProps.allProjectActivityType,
        selectedActivityType:
          activity_type &&
          newProps.projectDetails.id !== this.state.previousProjectId
            ? _.sortBy(activity_type, [type => type.toLowerCase()], ["asc"])
            : this.state.selectedActivityType,
        previousProjectId: newProps.projectDetails.id,
      });
    }
  }

  onShowEnagementDropDown = () => {
    this.setState({
      showEngagementDropDown: !this.state.showEngagementDropDown,
    });
  };

  showArchive = () => {
    this.setState({
      showArchive: !this.state.showArchive,
    });
  };
  dontShowArchive = () => {
    this.setState({
      showArchive: false,
    });
  };

  deleteHandler = (value, key, type, callback) => {
    const { activity_type } = this.state;
    let activityTypeCopy = [...activity_type];
    activityTypeCopy.splice(key, 1);
    let activitySortCopy = _.sortBy(
      activityTypeCopy,
      [type => type.toLowerCase()],
      ["asc"]
    );
    this.setState(
      {
        activity_type: activitySortCopy,
        selectedActivityType: activitySortCopy,
      },
      () => {
        callback(this.state.selectedActivityType);
      }
    );
  };

  validate = value => {
    return this.state.activity_type
      ? this.state.activity_type.includes(value)
      : false;
  };

  validateKanban = value => {
    return this.state.kanban_list
      ? this.state.kanban_list.includes(value)
      : false;
  };

  setSelectedActivityType = (e, activityType, setAll, callback) => {
    if (setAll) {
      this.setState({
        selectedActivityType: _.sortBy(
          [...this.state.allProjectActivityType],
          [type => type.toLowerCase()],
          ["asc"]
        ),
      });
    } else {
      if (e.target.checked) {
        this.setState(
          {
            selectedActivityType: _.sortBy(
              [...this.state.selectedActivityType, activityType],
              [type => type.toLowerCase()],
              ["asc"]
            ),
          },
          () => {
            callback(this.state.selectedActivityType);
          }
        );
      } else {
        this.setState(
          {
            selectedActivityType: _.sortBy(
              this.state.selectedActivityType.filter(
                type => type !== activityType
              ),
              [type => type.toLowerCase()],
              ["asc"]
            ),
          },
          () => {
            callback(this.state.selectedActivityType);
          }
        );
      }
    }
  };

  onCancelAddClick = callback => {
    this.setState(
      {
        selectedActivityType: this.state.activity_type
          ? _.sortBy(
              [...this.state.activity_type],
              [type => type.toLowerCase()],
              ["asc"]
            )
          : [],
      },
      () => {
        callback(this.state.selectedActivityType);
      }
    );
  };

  updateActivityType = callback => {
    //let activityTypenew=this.state.selectedActivityType.filter(activityType=>!this.state.activity_type.includes(activityType));
    this.setState(
      {
        activity_type: _.sortBy(
          [...this.state.selectedActivityType],
          [type => type.toLowerCase()],
          ["asc"]
        ),
      },
      () => {
        callback(this.state.selectedActivityType);
      }
    );
  };

  getWorkflowId = value => {
    let wfId;
    _.forIn(this.props.projectDetails.workflow, (wfValue, wfKey) => {
      if (wfValue.name === value) wfId = wfKey;
    });
    return wfId;
  };

  render() {
    const {
      clientLogo,
      showStatusDropDown,
      selectedStatusView,
      start,
      due,
      engagement,
      activity_type,
      kanban_list,
      showEngagementDropDown,
      allProjectActivityType,
    } = this.state;

    let startDate = moment(start).format("DD MMM YY");
    let endDate = moment(due).format("DD MMM YY");
    if (this.props.projectDetails) {
      const { name, status, archived } = this.props.projectDetails;
      let activityType = null;
      activityType = activity_type.map((value, index) => {
        return (
          <InputChips
            readOnly={true}
            classname="read-only"
            value={value}
            indexvalue={index}
            deleteHandler={this.deleteHandler}
            type="activity_type"
            key={value + "ACT" + index}
            addButton={false}
            handleAdd={this.handleAddActivity}
            validate={this.validate}
            allProjectActivityType={allProjectActivityType}
            setSelectedActivityType={this.setSelectedActivityType}
            selectedActivityType={this.state.selectedActivityType}
            onCancelAddClick={this.onCancelAddClick}
            updateActivityType={this.updateActivityType}
            showFilter={true}
          />
        );
      });
      activityType.push(
        <InputChips
          allProjectActivityType={allProjectActivityType}
          setSelectedActivityType={this.setSelectedActivityType}
          selectedActivityType={this.state.selectedActivityType}
          onCancelAddClick={this.onCancelAddClick}
          updateActivityType={this.updateActivityType}
          validate={this.validate}
          deleteHandler={this.deleteHandler}
          readOnly={true}
          handleAdd={this.handleAddActivity}
          classname="read-only"
          addButton={true}
          key="+ AAdd"
          type="activity_type"
          value="+ Add"
          showFilter={true}
        />
      );

      let kanbanList = null;
      kanbanList = kanban_list.map((value, index) => {
        return (
          <InputChips
            readOnly={true}
            classname="read-only"
            value={value}
            wfId={this.getWorkflowId(value)}
            indexvalue={index}
            deleteHandler={this.handleDeleteKanban}
            type="activity_type"
            key={value + "Kan" + index}
            addButton={false}
            handleAdd={this.handleAddKanban}
            validate={this.validateKanban}
          />
        );
      });
      kanbanList.push(
        <InputChips
          validate={this.validateKanban}
          deleteHandler={this.handleDeleteKanban}
          readOnly={true}
          handleAdd={this.handleAddKanban}
          classname="read-only"
          addButton={true}
          key="+ KAdd"
          type="activity_type"
          value="+ Add"
        />
      );

      return (
        <div className="ppl-settings-control-outer-holder">
          {/* <Loader size={100} loading={this.props.updateProjectLoaderState.fetching} /> */}
          <div className="ppl-settings-control-holder">
            <div className="ppl-settings-control-title">Created date</div>
            <div className="ppl-settings-indv-control-holder">
              {moment(this.props.projectDetails.created_at).format(
                "ddd, DD MMM YY"
              )}
            </div>
          </div>
          <div className="ppl-settings-control-holder">
            <div className="ppl-settings-control-title">
              <div className="img-container project-settings-img">
                <LogoUploader
                  image={clientLogo || addLogo}
                  update={clientLogo ? true : false}
                  updateLogo={this.updateLogo}
                  top={3}
                />
              </div>
            </div>
            <div className="ppl-settings-indv-control-holder">
              <input
                className="settings-input-area"
                type="text"
                value={this.state.name}
                autoComplete="off"
                onChange={this.handleOnChange}
                name="name"
                placeholder="Enter project name"
              />
            </div>
          </div>
          {/* <div className="ppl-settings-control-holder">
            <div className="cus-autocomp-holders">
              <div className="ppl-settings-control-title">Group</div>
              <div
                className="cus-autocomp-inner-holder ppl-settings-indv-control-holder"

                // onBlur={this.onBlurGroup}
              >
                <div
                  className="cus-autocomp-control-grp"
                  style={{ width: this.state.group.length * 10 + 20 }}
                >
                  <input
                    type="text"
                    onFocus={this.onFocusGroup}
                    value={this.state.group}
                    autoComplete="off"
                    onChange={this.handleGroupOnChange}
                    name="group"
                    className="grp-select"
                    placeholder="Select"
                  />
                  <span
                    className="ddown-icon-holder"
                    onClick={this.onShowAllGroups}
                  >
                    <img src={downArrow} />
                  </span>
                </div>
                {this.state.showGroupDropDown &&
                  this.state.filteredGroupList.length > 0 && (
                    <GroupDropDown
                      width={this.state.group.length * 10 + 20}
                      selectedGroup={this.state.group}
                      groupList={
                        this.state.showAllGroupsDropDown
                          ? this.props.groupList
                          : this.state.filteredGroupList
                      }
                      onSelectGroup={this.onSelectGroup}
                      onShowGroupDropDown={this.onShowGroupDropDown}
                    />
                  )}
              </div>
            </div>
          </div> */}

          <div className="ppl-settings-control-holder projStatus-holder">
            <div className="ppl-settings-control-title">Status</div>
            <div
              className="ppl-settings-indv-control-holder status-control-holder"
              ref={node => (this.outerDivStatusNode = node)}
            >
              <div
                className="ppl-settings-ddown-holder status-holder"
                onClick={this.onShowStatusDropDown}
              >
                <span>
                  {selectedStatusView
                    ? selectedStatusView
                    : getStatusView(status)}
                </span>
                {showStatusDropDown && (
                  <OutsideClickHandler
                    onOutsideClick={this.onOutsideClickStatus}
                  >
                    <StatusDropDown
                      onSelectValue={this.onSelectValue}
                      onShowStatusDropDown={this.onShowStatusDropDown}
                    />
                  </OutsideClickHandler>
                )}
              </div>
            </div>
          </div>

          <div className="ppl-settings-control-holder">
            <div className="cus-autocomp-holders">
              <div className="ppl-settings-control-title">Duration </div>
              <div className="cus-autocomp-inner-holder ppl-settings-indv-control-holder">
                <DateRangePicker
                  startDate={startDate}
                  endDate={endDate}
                  onChangeDate={this.onChangeDate}
                >
                  <div className="cus-autocomp-control date-picker">{`${startDate} - ${endDate}`}</div>
                </DateRangePicker>
              </div>
              <div className="ppl-settings-indv-control-holder"></div>
            </div>
          </div>

          <div className="ppl-settings-control-holder">
            <div className="ppl-settings-control-title">Estimated hours</div>
            <div className="ppl-settings-indv-control-holder">
              <input
                type="number"
                className="project-estimated"
                value={this.state.estimated_hours}
                onChange={this.handleOnChange}
                autoComplete="off"
                name="estimated_hours"
                placeholder="Enter time"
              />
            </div>
          </div>

          <div className="ppl-settings-control-holder">
            <div className="ppl-settings-control-title">Budget</div>
            <div className="ppl-settings-indv-control-holder">
              <div className="ppl-settings-billing-control-holder">
                <div className="ppl-settings-billing-unit project-estimated-dollar">
                  {this.props.currencyLogo}
                </div>
                <input
                  type="number"
                  className="project-estimated proj-budget"
                  value={this.state.budget}
                  onChange={this.handleOnChange}
                  autoComplete="off"
                  name="budget"
                  placeholder="Enter amount"
                />
              </div>
            </div>
          </div>
          <div className="ppl-settings-control-holder ">
            <div className="ppl-settings-control-title">Engagement type</div>
            <div className="ppl-settings-indv-control-holder">
              <div
                className="ppl-settings-ddown-holder status-holder engagement-type-project"
                ref={node => (this.EnagementNode = node)}
                onClick={this.onShowEnagementDropDown}
              >
                <span>
                  {engagement && engagement.length > 0
                    ? engagement
                    : "Not Applicable"}
                </span>
                {showEngagementDropDown && (
                  <OutsideClickHandler
                    onOutsideClick={this.onOutsideClickStatus}
                  >
                    <EngagementDropDown
                      onSelectEngagementType={this.onSelectEngagementType}
                      onShowEnagementDropDown={this.onShowEnagementDropDown}
                      currentWorkspace={this.props.currentWorkSpace}
                    />
                  </OutsideClickHandler>
                )}
              </div>
            </div>
          </div>
          <div className="ppl-settings-control-holder">
            <div className="ppl-settings-control-title"> Description</div>
            <div className="ppl-settings-indv-control-holder">
              <TextareaAutosize
                autoc-resize="true"
                className="proj-des-settings"
                value={this.state.description}
                onChange={this.handleOnChange}
                autoComplete="off"
                name="description"
                placeholder="What is this project about?"
              ></TextareaAutosize>
            </div>
          </div>

          <div className="ppl-settings-control-holder no-icon">
            <div className="ppl-settings-control-title">Activity type</div>
            <div className="ppl-settings-indv-control-holder  add-team-project">
              {/* <ChipInput
                // defaultValue={activity_type}
                value={activity_type}
                onAdd={(chip) => this.handleAddActivity(chip)}
                onDelete={(chip, index) =>
                  this.handleDeleteActivity(chip, index)
                }
                className="chip-input"
              />      */}
              <div className="input-chips">{activityType}</div>
            </div>
          </div>

          <div className="ppl-settings-control-holder no-icon kanban-project-settings">
            <div className="ppl-settings-control-title ">Kanban list</div>
            <div className="ppl-settings-indv-control-holder  add-team-project">
              {/* <ChipInput
                value={kanban_list}
                onAdd={(chip) => this.handleAddKanban(chip)}
                onDelete={(chip, index) => this.handleDeleteKanban(chip, index)}
                className="chip-input"
              /> */}
              <div className="input-chips">{kanbanList}</div>
            </div>
          </div>
          <div className="button-row">
            <button
              disabled={!this.state.name}
              onClick={this.updateProject}
              className="settings-btn proj-settings-save"
            >
              Save
            </button>
            <div className="ppl-remove-member-container">
              <OutsideClickHandler onOutsideClick={this.dontShowArchive}>
                <span
                  className="ppl-remove-member"
                  onClick={this.showArchive}
                  // onMouseOver="this.style.backgroundColor='none'"
                >
                  {archived ? "Unarchive" : "Archive"} project
                </span>
                {/* delete people */}
                {this.state.showArchive && (
                  <div className="Edit-board-ddown">
                    <div className="confirmation-box-remove">
                      <p className="user-name">
                        {archived ? "Unarchive" : "Archive"} {name}?
                      </p>
                      <hr className="divider-remove-box"></hr>
                      <p className="messageremove-box">
                        Are you sure you want to{" "}
                        {archived ? "unarchive" : "archive"} this project?
                      </p>
                      <div className="button-box">
                        <button
                          className="cancel-button-remove-box"
                          onClick={this.showArchive}
                        >
                          Cancel
                        </button>
                        <button
                          className="confirm-button-remove-box"
                          onClick={() => {
                            this.props.onArchiveUnarchive(
                              this.props.projectDetails
                            );
                            this.showArchive();
                          }}
                        >
                          {archived ? "Unarchive" : "Archive"}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </OutsideClickHandler>
            </div>
          </div>
        </div>
      );
    } else return null;
    // <Loader size={100} loading={true} />;
  }
}

const mapStateToProps = state => {
  return {
    updateProjectLoaderState: state.projectReducer.updateProjectLoaderState,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateProjectAction({ auth_token, queryParam, body }, callback) {
      dispatch(
        action.updateProjectAction({ auth_token, queryParam, body }, callback)
      );
    },
    getProjectAction({ auth_token, queryParam }, callback) {
      dispatch(action.getProjectsAction({ auth_token, queryParam }, callback));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectSettingsForm);
