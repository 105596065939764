import React, { useEffect, useState } from "react";
import searchField from "../../styles/img/svg/settings-search-icon1.svg";
// import OutsideClickHandler from "react-outside-click-handler";
import checkMark from "../../styles/img/svg/checkmark.svg";
import _ from "lodash";
import * as action from "../../actions/index";
import { Loader } from "../common/loader";
import { connect } from "react-redux";
import Avatar from "react-avatar";
// import firebase from "../../firebase";
import {
  getProjectDetails,
  requestToJoinProject,
} from "../../services/projectapi";

const mapStateToProps = state => {
  return {
    projectList: state.projectReducer.listOfProjects.list,
    currentUser: state.loginReducer.loginAuth.user_details,
    selectedCustomerDetails:
      state.wrorkSpaceReducer.selectedCustomerDetails.selectedWorkspace,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateProjectAction({ auth_token, queryParam, body }, callback) {
      dispatch(
        action.updateProjectAction({ auth_token, queryParam, body }, callback)
      );
    },
  };
};

const ProjectAccessRequest = props => {
  const [searchText, setSearchText] = useState("");
  const [projectsForSearchBox, setProjectsForSearchBox] = useState([]);
  const [updatedDisplayedProjects, setUpdatedDisplayedProjects] = useState(
    null
  );
  const [selectedProject, setSelectedProject] = useState([]);
  const [fetchingProjects, setFetchingProjects] = useState(false);

  useEffect(() => {
    setFetchingProjects(true);
    getProjectDetails(
      {
        auth_token: localStorage.getItem("customer_auth_token"),
        queryParam: `?archived=false&requested_access=false&requester_id=${props.currentUser.id}`,
      },
      res => {
        if (res && !res.error && !res.stdErr) {
          setFetchingProjects(false);
          setProjectsForSearchBox(
            res.filter(
              proj => !props.projectList.find(index => proj.id === index.id)
            )
          );
        }
      }
    );
  }, []);

  const onChangeSearch = event => {
    let searchText = event.target.value;
    setSearchText(searchText);
    let updatedDisplayedProjects = projectsForSearchBox.filter(
      project =>
        project.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
    );
    setSearchText(searchText);
    setUpdatedDisplayedProjects(updatedDisplayedProjects);
  };

  const onCancel = event => {
    event.stopPropagation();
    props.onHideSearch();
  };

  const onSelectWorkSpaceUser = (event, clickedProject) => {
    if (
      selectedProject.filter(project => project.id === clickedProject.id)
        .length > 0
    ) {
      return setSelectedProject(
        selectedProject.filter(project => project.id !== clickedProject.id)
      );
    }
    const projectSelected = projectsForSearchBox.filter(
      project => project.id === clickedProject.id
    )[0];
    setSelectedProject([...selectedProject, projectSelected]);
  };

  const onConfirmClicked = e => {
    selectedProject.forEach(project => {
      const body = {
        access_status: "REQUEST",
        project_id: project.id,
        user_id: parseInt(props.currentUser.id),
        is_archived: false,
      };
      requestToJoinProject(
        { auth_token: props.selectedCustomerDetails.auth_token, body },
        response => {
          if (response) {
          }
        }
      );
    });
    onCancel(e);
  };

  let searchBoxProjects = [];
  if (_.isNull(updatedDisplayedProjects) == true) {
    searchBoxProjects = projectsForSearchBox;
  } else {
    searchBoxProjects = updatedDisplayedProjects;
  }
  return (
    <div
      className="edit-board-ddown proj-ddown"
      style={{ top: "30px", right: "38px", left: "auto" }}
    >
      <div className="edit-board-search-holder assign-proj-search">
        <input
          type="text"
          className="edit-board-search-control proj-search-box"
          placeholder="Search"
          onChange={onChangeSearch}
          value={searchText}
          autoFocus
        />
        <div className="edit-search-icon-holder">
          <img src={searchField} alt="search" />
        </div>
      </div>

      <div className="search-data-container" style={{ maxHeight: "244px" }}>
        {searchBoxProjects.length > 0 &&
          searchBoxProjects.map((project, index) => (
            <div
              key={index}
              onClick={event => onSelectWorkSpaceUser(event, project)}
              className="search-data-list"
            >
              <div className="user-sqimg-icon project-assign-sqrimg">
                {!project.img && (
                  <Avatar
                    style={{
                      width: "35px",
                      height: "35px",
                      borderRadius: "10px !important",
                    }}
                    name={project.name}
                    maxInitials={1}
                  ></Avatar>
                )}

                {project.img && <img src={project.img} />}
              </div>
              <div className="user-details-holder user-workspace">
                <div className="user-name proj-access">{project.name}</div>
                {/* <div className="user-email">{project.group}</div> */}
              </div>
              {selectedProject.filter(
                selectproject => selectproject.id === project.id
              ).length > 0 && (
                <img className="user-add-action check-select" src={checkMark} />
              )}
            </div>
          ))}
        {fetchingProjects && (
          <div className="no-result-found">
            <Loader size={100} loading={fetchingProjects} />
          </div>
        )}
        {projectsForSearchBox.length === 0 &&
          !fetchingProjects &&
          !searchText && (
            <div className="no-result-found">No projects to request</div>
          )}
        {searchText && searchBoxProjects.length === 0 && (
          <div className="no-result-found">No results found</div>
        )}
      </div>
      <div className="project-assign-hr" />

      <div className="project-assign-bottom">
        <button className="project-assign-cancel" onClick={onCancel}>
          Cancel
        </button>
        <button
          className="applyBtn btn btn-sm btn-primary project-assign-confirm"
          disabled={selectedProject.length < 1 ? true : false}
          onClick={onConfirmClicked}
        >
          Confirm
        </button>
      </div>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectAccessRequest);
