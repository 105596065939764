/***
 * Author: Madhusudhan Seetharamiah
 * **
 */
import React, { Component } from "react";
import { actions } from "react-redux-form";
import routesHelper from "../router/routeshelper";
import ProjectEditHeader from "./common/projectEditHeader";
import { Scrollbar } from "react-scrollbars-custom";
import { updateLastActive } from "../services/loginapi";
import SettingsLanding from "../components/settings/settingslanding";
import ProjectSettingsForm from "./settings/projectSettingsForm";
import PeopleAccessSection from "./settings/peopleAccessSection";
import "../styles/scss/organization.scss";
import "../styles/scss/editProjectDetails.scss";
import _ from "lodash";
import { getCurrencyLogo, logout } from "../helpers/generichelper";
import * as action from "../actions/workspace/workspaceaction";
import * as allAction from "../actions/index";
import {
  getProjectsAction,
  getArchivedProjectsAction,
  unArchivedArchivedProject,
  clearProjectsAction,
} from "../actions/project/projectaction";
import { getArchivedCommentsAction } from "../actions/comments/commentsaction";
import { getAllCustomerForPeopleAction } from "../actions/people/peopleaction";
import { updateLoginAuth } from "../actions/login/loginaction";
import {
  getUserDetailsAction,
  uploadPhotoToS3Bucket,
  updateTabState,
  updatedSearchText,
  expandNavBar,
  updateUserProfileAction,
  updateDashboardTabState,
} from "../actions/common/commonactions";
import { getCustomerInfo } from "../actions/billing/billing";
import UserActionDropDown from "../components/common/useractiondropdown";
import { connect } from "react-redux";
import BreadCrumb from "../components/common/breadcrumb";
import ArchivedLanding from "./archived/archivedLanding";
import UserProfile from "../components/profile/userprofile.jsx";
import MoreChecklist from "../components/archived/moreChecklist";
import MoreComments from "../components/archived/moreComments";
import { workspaceConstant } from "../constants/workspaceconstants";
import { ArchivedConstant } from "../constants/archivedConstant";
import FindWorkspace from "../components/workspace/findworkspace";
import WorkspaceInvitations from "./workspace/workspaceinvitations";
import CreateWorkspace from "../components/workspace/createworkspace";
import "react-toastify/scss/main.scss";
import JoinWorkspace from "../components/workspace/joinworkspace";
import AddUsersToWorkspace from "../components/workspace/adduserstoworkspace";
import {
  requestToJoinWorkspace,
  // getCustomerDetails,
  switchWorkspace,
} from "../services/workspaceapi";
import Activity from "../components/activity/activityWrapper";
import ProjectsLanding from "../components/projects/projectsLanding";
import DashboardLanding from "../components/dashboard/dashboardLanding";
import PerformaActions from "../components/activity/performaactions";
import {
  totalActvityCountAction,
  clearReducerWithPrioritizeAction,
} from "../actions/workspace/workspaceaction";
import {
  getSearchedArchivedActivitiesAction,
  clearSearchedArchivedActivitiesAction,
} from "../actions/archived/archivedActions";
import { activities } from "../constants/activitiescontants";
import { projectConstants } from "../constants/projectconstants";
import { reports } from "../constants/reportsconstants";
import { Loader } from "./common/loader";
import { isAuthenticated } from "../helpers/isauthenticated";
import { getProfileDetails } from "../helpers/generichelper";
import SidebarNav from "./common/sidebar";
import { ToastContainer, Slide } from "react-toastify";
import PeopleSettings from "../components/people/peoplesettings";
import WorkspaceSettings from "./workspace/workspacesettings";
import {
  fetchTimesheetAction,
  getActivitiesByProjectAction,
} from "../actions/activities/activitiesaction";
import moment from "moment";
import MoreActivity from "./archived/moreActivity";
import ReportLanding from "./report/reportLanding";
import MoreArchivedProjects from "./archived/moreProjects";
import {
  getChecklistAction,
  getArchivedChecklistAction,
} from "../actions/checklist/checklist";
import { workloadConstant } from "../constants/workloadConstant";
import Workload from "./workload/workload";
import { kanbanConstant } from "../constants/kanbanconstants";
import Kanban from "./kanban/kanban";
import Tour from "reactour";
import ArrowTop from "../components/webtour/arrowTop";
import DialogWrapper from "./common/dialog";
import "../styles/scss/web_tour.scss";

// //
// import { updateLatworkedWorkspace } from "../actions/login/loginaction";
// import { getAllWorkpsaceActivities } from "../actions/workspace/workspaceaction";
import {
  getSystemNotificationsAction,
  getGlobalNotificationsAction,
  getAllNotificationsAction,
  getYourNotificationsAction,
  getNewAllNotificationAction,
  getNewYouNotificationAction,
} from "../actions/notifications/notificationaction";
import { getIsSuperAdmin } from "../helpers/reportlanding";
import { getRandomImage } from "../services/unsplashaapi";
// import ArrowRight from "./webtour/arrowRight";
import ArrowBottom from "./webtour/arrowBottom";
import { dashboardConstants } from "../constants/dashboardConstants";
import firebase from "../firebase";
import { registerFirebaseUserToken } from "../services/notificationapis";
import { getFcmToken, setFcmToken } from "../helpers/notificationHelper";
import ChangeGooglePopup from "./profile/changeGooglePopup";

const mapStateToProps = (state) => {
  return {
    customerLoaderState: state.wrorkSpaceReducer.customerLoaderState,
    customerLoaderStateForDroDown:
      state.wrorkSpaceReducer.customerLoaderStateForDroDown,
    listOfCustomer: state.wrorkSpaceReducer.listOfCustomer.list,
    pendingCustomerLoaderState:
      state.wrorkSpaceReducer.pendingCustomerLoaderState,
    updateCustomerLoaderState:
      state.wrorkSpaceReducer.updateCustomerLoaderState,
    createWorkpsaceLoaderState:
      state.wrorkSpaceReducer.createWorkpsaceLoaderState,
    updatePrioritizedActivitiesLoaderState:
      state.wrorkSpaceReducer.updatePrioritizedActivitiesLoaderState,
    updateDeprioritizedActivitiesLoaderState:
      state.wrorkSpaceReducer.updateDeprioritizedActivitiesLoaderState,
    customerPhotoUpdateLoaderState:
      state.commonReducer.customerPhotoUpdateLoaderState,
    activityLoaderState: state.activityReducer.activityLoaderState,
    activitiesByProjectLoaderState:
      state.activityReducer.activitiesByProjectLoaderState,
    workloadActivitiesLoaderState:
      state.workloadReducer.workloadActivitiesLoaderState,
    foundCustomersSuggestions:
      state.wrorkSpaceReducer.suggestWorkspaceList.listOfSuggetions,
    selectedCustomerDetails:
      state.wrorkSpaceReducer.selectedCustomerDetails.selectedWorkspace,
    requestedToJoinWorkspace: state.wrorkSpaceReducer.listOfPendingCustomer,
    listOfPendingCustomerInvites:
      state.wrorkSpaceReducer.listOfPendingCustomerInvites,
    navbarState: state.commonReducer.navBarState.expanded,
    currentUser: state.loginReducer.loginAuth.user_details,
    totalTimeForTheDay: state.commonReducer.totalTimeForTheDay,
    anyTimerStatus: state.commonReducer.timerStatus,
    projectListLoaderState: state.projectReducer.projectListLoaderState,
    getCommentsLoaderState: state.commentsReducer.getCommentsLoaderState,
    effortOverviewLoaderState: state.dashboardReducer.effortOverviewLoaderState,
    individualEffortLoaderState:
      state.dashboardReducer.individualEffortLoaderState,
    projectAccessReqTeamLoaderState:
      state.dashboardReducer.projectAccessReqTeamLoaderState,
    missedTimeSheetsLoaderState:
      state.dashboardReducer.missedTimeSheetsLoaderState,
    effortOverviewPersonalLoaderState:
      state.dashboardReducer.effortOverviewPersonalLoaderState,
    projectAccessReqPersonalLoaderState:
      state.dashboardReducer.projectAccessReqPersonalLoaderState,
    reportFilters: state.timeSheetReducer.filters,
    timeSheetListLoaderState: state.timeSheetReducer.timeSheetListLoaderState,
    listOfTimeSheet: state.timeSheetReducer.listOfTimeSheet.list,
    tabState: state.commonReducer.tabState.projectsLanding,
    dashboardTabState: state.commonReducer.tabState.dashboardLanding,
    projectList: state.projectReducer.listOfProjects.list,
    searchContent: state.commonReducer.searchContent.text,
  };
};

const mapDispatchProps = (dispatch) => {
  return {
    getUserDetailsAction: ({ queryparams }, callback) => {
      dispatch(getUserDetailsAction({ queryparams }, callback));
    },
    getAllCustomersAction({ auth_token, loaderKey }, callback) {
      dispatch(
        action.getAllCustomerForUserAction(
          {
            auth_token,
            loaderKey,
          },
          callback
        )
      );
    },
    getCustomerInfo: (callback) => {
      dispatch(getCustomerInfo(callback));
    },
    suggestWorkspaceActions: ({ auth_token, user_id }, callback) => {
      dispatch(
        action.suggestWorkspaceAction({ auth_token, user_id }, callback)
      );
    },
    getAllPendingListAction(auth_token, callback) {
      dispatch(action.getAllPendingReuestsAction(auth_token, callback));
    },
    getAllPendingInviteListAction(auth_token, callback) {
      dispatch(action.getAllPendingInvitesAction(auth_token, callback));
    },
    uploadPhotoToS3BucketAction: ({ file, loaderKey }, callback) => {
      dispatch(
        uploadPhotoToS3Bucket(
          {
            file,
            loaderKey,
          },
          callback
        )
      );
    },
    createWorkspaceActions: (
      { name, img, auth_token, img_name, super_admin_id },
      callback
    ) => {
      dispatch(
        action.createWorkspaceAction(
          {
            name,
            img,
            auth_token,
            img_name,
            super_admin_id,
          },
          callback
        )
      );
    },
    updateSelectedWorkpaceDetailsAction(selectedWorspace) {
      dispatch(action.updateSelectedWorkpaceDetails(selectedWorspace));
    },
    getSelectedWorkpsaceArchivedActivities(
      {
        auth_token,
        client_id,
        activity_type,
        is_active,
        user_id,
        is_archived,
        page,
        item_per_page,
      },
      callback
    ) {
      dispatch(
        action.getSelectedWorkpsaceArchivedActivities(
          {
            auth_token,
            client_id,
            activity_type,
            is_active,
            user_id,
            is_archived,
            page,
            item_per_page,
          },
          callback
        )
      );
    },
    getSelectedWorkpsaceActivities(
      {
        auth_token,
        client_id,
        activity_type,
        is_active,
        user_id,
        is_archived,
        page,
        item_per_page,
      },
      callback
    ) {
      dispatch(
        action.getSelectedWorkpsaceActivities(
          {
            auth_token,
            client_id,
            activity_type,
            is_active,
            user_id,
            is_archived,
            page,
            item_per_page,
          },
          callback
        )
      );
    },
    getTimerStatusForWorkspaceAction({ auth_token }, callback) {
      dispatch(
        action.getTimerStatusForWorkspaceAction({ auth_token }, callback)
      );
    },
    // getAllWorkpsaceActivities(
    //   { auth_token, client_id, activity_type, is_active, user_id, is_archived },
    //   callback
    // ) {
    //   dispatch(
    //     getAllWorkpsaceActivities(
    //       {
    //         auth_token,
    //         client_id,
    //         activity_type,
    //         is_active,
    //         user_id,
    //         is_archived
    //       },
    //       callback
    //     )
    //   );
    // },
    clearReducerWithPrioritizeAction() {
      dispatch(clearReducerWithPrioritizeAction());
    },
    clearDashboardReducerAction() {
      dispatch(allAction.clearDashboardReducerAction());
    },
    clearProjectsAction() {
      dispatch(clearProjectsAction());
    },
    clearArchivedChecklistAction() {
      dispatch(allAction.clearArchivedChecklistAction());
    },
    clearTimesheetAction() {
      dispatch(allAction.clearTimesheetAction());
    },
    updateTabStateAction({ projectsLanding }) {
      dispatch(updateTabState({ projectsLanding }));
    },
    updateDashboardTabStateAction({ dashboardLanding }) {
      dispatch(updateDashboardTabState({ dashboardLanding }));
    },
    updatedSearchTextAction({ searchtext, activeClass }) {
      dispatch(updatedSearchText({ searchtext, activeClass }));
    },
    updateLoginAuth(user_details) {
      dispatch(updateLoginAuth(user_details));
    },
    expandNavBar(navbarState) {
      dispatch(expandNavBar(navbarState));
    },
    updateUserProfileAction(user, cb) {
      dispatch(updateUserProfileAction(user, cb));
    },
    getAllCustomer: ({ auth_token }, callback) => {
      dispatch(getAllCustomerForPeopleAction({ auth_token }, callback));
    },
    getTimeSheetsAction: ({ auth_token, queryParam }, callback) => {
      dispatch(
        allAction.getTimeSheetsAction({ auth_token, queryParam }, callback)
      );
    },
    updateReportFilterAction: (filters) => {
      dispatch(allAction.updateFilterAction(filters));
    },
    updateReportSortAction: (sort) => {
      dispatch(allAction.updateSortAction(sort));
    },
    fetchTimesheetAction({ from_date, to_date, user_id }, callback) {
      dispatch(fetchTimesheetAction({ from_date, to_date, user_id }, callback));
    },
    totalActvityCountAction({ activity_type, user_id, auth_token }) {
      dispatch(totalActvityCountAction({ activity_type, user_id, auth_token }));
    },
    getProjectsAction({ auth_token, queryParam }, callback) {
      dispatch(getProjectsAction({ auth_token, queryParam }, callback));
    },
    getArchivedProjectsAction({ auth_token, queryParam }, callback) {
      dispatch(getArchivedProjectsAction({ auth_token, queryParam }, callback));
    },
    getArchivedCommentsAction(
      { user_id, searchText, page, perPage },
      callback
    ) {
      dispatch(
        getArchivedCommentsAction(
          { user_id, searchText, page, perPage },
          callback
        )
      );
    },
    unArchivedArchivedProject: ({ body }, callback) => {
      dispatch(unArchivedArchivedProject({ body }, callback));
    },
    getChecklistAction({ queryParams }, callback) {
      dispatch(getChecklistAction({ queryParams }, callback));
    },
    getArchivedChecklistAction({ queryParams }, callback) {
      dispatch(getArchivedChecklistAction({ queryParams }, callback));
    },
    getSystemNotificationsAction: ({ auth_token }, callback) => {
      dispatch(getSystemNotificationsAction({ auth_token }, callback));
    },
    getGlobalNotificationsAction: (callback) => {
      dispatch(getGlobalNotificationsAction(callback));
    },
    getAllNotificationsAction(
      { auth_token, page, per_page, recipient_id },
      callback
    ) {
      dispatch(
        getAllNotificationsAction(
          { auth_token, page, per_page, recipient_id },
          callback
        )
      );
    },
    getYourNotificationsAction(
      { auth_token, page, per_page, recipient_id },
      callback
    ) {
      dispatch(
        getYourNotificationsAction(
          { auth_token, page, per_page, recipient_id },
          callback
        )
      );
    },
    getNewYouNotificationAction(
      { auth_token, page, per_page, recipient_id },
      callback
    ) {
      dispatch(
        getNewYouNotificationAction(
          { auth_token, page, per_page, recipient_id },
          callback
        )
      );
    },
    getNewAllNotificationAction(
      { auth_token, page, per_page, recipient_id },
      callback
    ) {
      dispatch(
        getNewAllNotificationAction(
          { auth_token, page, per_page, recipient_id },
          callback
        )
      );
    },
    getPrioritizedActivitiesForWorkspaceAction: (
      { auth_token, activity_type, is_active, user_id, is_archived },
      callback
    ) => {
      dispatch(
        action.getPrioritizedActivitiesForWorkspaceAction(
          {
            auth_token,
            activity_type,
            is_active,
            user_id,
            is_archived,
          },
          callback
        )
      );
    },
    getDeprioritizedActivitiesForWorkspaceAction: (
      {
        auth_token,
        activity_type,
        is_active,
        user_id,
        is_archived,
        page,
        item_per_page,
      },
      callback
    ) => {
      dispatch(
        action.getDeprioritizedActivitiesForWorkspaceAction(
          {
            auth_token,
            activity_type,
            is_active,
            user_id,
            is_archived,
            page,
            item_per_page,
          },
          callback
        )
      );
    },
    getActivitiesByProjectAction(
      { activity_type, is_active, project_id, is_archived, user_id },
      callback
    ) {
      dispatch(
        getActivitiesByProjectAction(
          { activity_type, is_active, project_id, is_archived, user_id },
          callback
        )
      );
    },
    getEffortOverviewAction({ auth_token, queryParam }, callback) {
      dispatch(
        allAction.getEffortOverviewAction({ auth_token, queryParam }, callback)
      );
    },
    getIndividualEffortAction({ auth_token, queryParam }, callback) {
      dispatch(
        allAction.getIndividualEffortAction(
          { auth_token, queryParam },
          callback
        )
      );
    },
    getProjectAccessReqTeamAction({ auth_token, queryParam }, callback) {
      dispatch(
        allAction.getProjectAccessReqTeamAction(
          { auth_token, queryParam },
          callback
        )
      );
    },
    getMissedTimeSheetsAction({ auth_token, queryParam }, callback) {
      dispatch(
        allAction.getMissedTimeSheetsAction(
          { auth_token, queryParam },
          callback
        )
      );
    },
    getEffortOverviewPersonalAction({ auth_token, queryParam }, callback) {
      dispatch(
        allAction.getEffortOverviewPersonalAction(
          { auth_token, queryParam },
          callback
        )
      );
    },
    getProjectAccessReqPersonalAction({ auth_token, queryParam }, callback) {
      dispatch(
        allAction.getProjectAccessReqPersonalAction(
          { auth_token, queryParam },
          callback
        )
      );
    },
    getProjectStatusAction({ auth_token, queryParam }, callback) {
      dispatch(
        allAction.getProjectStatusAction({ auth_token, queryParam }, callback)
      );
    },
    getLeaderBoardAction({ auth_token, queryParam }, callback) {
      dispatch(
        allAction.getLeaderBoardAction({ auth_token, queryParam }, callback)
      );
    },
    getSearchedArchivedActivitiesAction: (params, callback) => {
      dispatch(getSearchedArchivedActivitiesAction(params, callback));
    },
    clearSearchedArchivedActivitiesAction: () => {
      dispatch(clearSearchedArchivedActivitiesAction());
    },
    setDefaultProjectDetails: (values) =>
      actions.merge("rrfState.projectSettingsForm", values),
  };
};

class App extends Component {
  constructor(props) {
    super(props);
    let openTourDialog = false;
    if (
      localStorage.getItem("firstTime") &&
      localStorage.getItem("firstTime") === "true"
    ) {
      openTourDialog = true;
    }
    localStorage.setItem("firstTime", false);
    this.state = {
      timer: new Date().getTime(),
      showLoginAgainPopup: false,
      showRemovedFromWpPopup: false,
      bgName: null,
      credits_link: null,
      author_link: null,
      author: null,
      isTourOpen: openTourDialog,
      style:
        "linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('https://source.unsplash.com/random') no-repeat",
    };
    window.alertMessage = this.alertMessage.bind(this);
    window.alertWorkspaceMsg = this.alertWorkspaceMsg.bind(this);
  }

  alertMessage() {
    if (!document.getElementById("login-again-popup"))
      this.setState({ showLoginAgainPopup: true });
  }

  alertWorkspaceMsg() {
    this.setState({ showRemovedFromWpPopup: true });
  }

  onCloseLoginPopup = () => {
    if (this.state.showLoginAgainPopup) {
      this.setState(
        { showLoginAgainPopup: false, showRemovedFromWpPopup: false },
        () => logout()
      );
    }
    if (this.state.showRemovedFromWpPopup) {
      this.setState({ showRemovedFromWpPopup: false }, () => logout());
    }
  };

  UNSAFE_componentWillMount() {
    const {
      router,
      customerLoaderState,
      getUserDetailsAction,
      getAllCustomersAction,
      // getCustomerInfo,
      // getSelectedWorkpsaceActivities,
      // getPrioritizedActivitiesForWorkspaceAction,
      // getDeprioritizedActivitiesForWorkspaceAction,
      // updateSelectedWorkpaceDetailsAction,
      // getSelectedWorkpsaceArchivedActivities,
      updateLoginAuth,
      // getAllCustomer,
      listOfCustomer,
      // currentUser,
      // fetchTimesheetAction,
      // totalActvityCountAction,
      // getProjectsAction,
      // getArchivedProjectsAction,
      location,
      // getChecklistAction,
      // getArchivedChecklistAction,
      // getSystemNotificationsAction,
      // getAllNotificationsAction,
      // getYourNotificationsAction
    } = this.props;

    const userDetails = getProfileDetails();
    // const auth_token = routesHelper.getAuthToken();
    // console.log(this.props.router);
    if (!isAuthenticated()) {
      return router.push(routesHelper.getLoginPath());
    } else {
      if (localStorage.getItem("expandedNavbar") === null)
        localStorage.setItem("expandedNavbar", true);
      if (!localStorage.getItem("location")) {
        let lastActiveZone = {
          lastActiveOn: moment().format("YYYY-MM-DD"),
          lastUsedTimeZone: moment.tz.guess(),
        };
        updateLastActive(
          {
            lastActiveOn: lastActiveZone.lastActiveOn,
            lastUsedTimeZone: lastActiveZone.lastUsedTimeZone,
          },
          (response) => {
            if (response.id)
              localStorage.setItem("location", JSON.stringify(lastActiveZone));
          }
        );
      }
      userDetails.auth_token = routesHelper.getAuthToken();
      if (localStorage.getItem("bg_image")) {
        const imgObj = JSON.parse(localStorage.getItem("bg_image"));
        this.setState({
          author_link: imgObj.author_link,
          credits_link: imgObj.credits_link,
          author: imgObj.author,
          bgName: imgObj.bgName,
          style: imgObj.style,
        });
      } else
        getRandomImage((response) => {
          if (response && !response.stdErr && !response.errors) {
            this.setState({
              author_link:
                "https://unsplash.com/@" +
                response.user.username +
                "?utm_source=gtd&utm_medium=referral&utm_campaign=api-credit",
              author: response.user.name,
              bgName: response.description,
              credits_link:
                "https://unsplash.com/?utm_source=gtd&utm_medium=referral&utm_campaign=api-credit",
              style:
                "linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(" +
                response.urls.regular +
                ") no-repeat",
            });
          } else
            this.setState({
              credits_link:
                "https://unsplash.com/?utm_source=gtd&utm_medium=referral&utm_campaign=api-credit",
            });
        });
      updateLoginAuth(userDetails);
      getUserDetailsAction(
        {
          queryparams: `?id=${localStorage.getItem("id")}`,
        },
        (response) => {
          if (response && !response.stdErr) {
            localStorage.setItem("data", JSON.stringify(response[0].data));
            updateLoginAuth({ ...userDetails, data: response[0].data });
            if (customerLoaderState.fetched && listOfCustomer) {
              if (
                listOfCustomer.length === 0 &&
                (location.pathname === "/workspace/create" ||
                  location.pathname === "/workspace/pending-invites" ||
                  location.pathname === "/workspace/join" ||
                  location.pathname === "/workspace/add-users" ||
                  location.pathname === "/workspace/find" ||
                  location.pathname === "/project/create")
              ) {
                return;
              }
              if (listOfCustomer.length === 0) {
                return this.props.router.push(routesHelper.getFinishProfile);
              }
              this.getAllWorkspaceRelatedDetails(listOfCustomer);
            } else {
              const auth_token = routesHelper.getAuthToken();
              getAllCustomersAction({ auth_token }, (res) => {
                if (res && !res.error) {
                  if (
                    res.length === 0 &&
                    (location.pathname === "/workspace/create" ||
                      location.pathname === "/workspace/pending-invites" ||
                      location.pathname === "/workspace/join" ||
                      location.pathname === "/workspace/add-users" ||
                      location.pathname === "/workspace/find" ||
                      location.pathname === "/project/create")
                  ) {
                    return;
                  }
                  if (res.length === 0) {
                    return this.props.router.push(
                      routesHelper.getFinishProfile
                    );
                  }
                  this.getAllWorkspaceRelatedDetails(res);
                }
              });
            }
          }
        }
      );
    }
  }

  getAllWorkspaceRelatedDetails = (res) => {
    const {
      router,
      params,
      getCustomerInfo,
      // getSelectedWorkpsaceActivities,
      getPrioritizedActivitiesForWorkspaceAction,
      getDeprioritizedActivitiesForWorkspaceAction,
      getActivitiesByProjectAction,
      getTimerStatusForWorkspaceAction,
      updateSelectedWorkpaceDetailsAction,
      getSelectedWorkpsaceArchivedActivities,
      // updateLoginAuth,
      getAllCustomer,
      // listOfCustomer,
      // currentUser,
      fetchTimesheetAction,
      // totalActvityCountAction,
      getProjectsAction,
      getArchivedProjectsAction,
      getArchivedCommentsAction,
      // location,
      // getChecklistAction,
      getArchivedChecklistAction,
      getSystemNotificationsAction,
      getGlobalNotificationsAction,
      getAllNotificationsAction,
      getYourNotificationsAction,
      updateDashboardTabStateAction,
      getEffortOverviewAction,
      getIndividualEffortAction,
      getProjectAccessReqTeamAction,
      getMissedTimeSheetsAction,
      getEffortOverviewPersonalAction,
      getProjectAccessReqPersonalAction,
      getProjectStatusAction,
      getLeaderBoardAction,
    } = this.props;
    const currentLocation = router && router.location.pathname;
    const userDetails = getProfileDetails();

    const currentCustomerID = JSON.parse(localStorage.getItem("data"));
    const last_worked_workspace_id =
      currentCustomerID &&
      !_.isNull(currentCustomerID) &&
      currentCustomerID.last_worked_workspace
        ? parseInt(currentCustomerID.last_worked_workspace)
        : null;
    // console.log(last_worked_workspace_id, currentUser);

    const selectedCustomerDetails =
      res.filter((workspace) => workspace.id === last_worked_workspace_id)
        .length > 0
        ? res.filter(
            (workspace) => workspace.id === last_worked_workspace_id
          )[0]
        : res[0];

    updateSelectedWorkpaceDetailsAction(selectedCustomerDetails);

    //Update selected workspace in backend then call Workspace details
    switchWorkspace(
      { customer_id: selectedCustomerDetails.id },
      (switchRes) => {
        if (switchRes && !switchRes.hasOwnProperty("stdErr")) {
          // Get timer running status
          getTimerStatusForWorkspaceAction({
            auth_token: routesHelper.getAuthToken(),
          });

          //Get Subscription details of workspace
          getCustomerInfo((response) => {});

          if (currentLocation !== routesHelper.kanbanPath(params.projId)) {
            // Get workspace users
            getAllCustomer({}, (response) => {
              if (response && !response.stdErr) {
              }
            });
          }
          if (currentLocation === routesHelper.kanbanPath(params.projId)) {
            const isSuperAdmin = getIsSuperAdmin(
              userDetails,
              selectedCustomerDetails
            );
            const user_id = isSuperAdmin ? null : parseInt(userDetails.id);
            getActivitiesByProjectAction(
              {
                activity_type: "ACTIVITY",
                is_active: true,
                project_id: params.projId,
                is_archived: false,
                user_id,
              },
              (response) => {}
            );
          }

          // Get unarchived projects
          let queryParamUnarchieved = getIsSuperAdmin(
            userDetails,
            selectedCustomerDetails
          )
            ? `?archived=false`
            : `?user_id=${parseInt(userDetails.id)}&archived=false`;
          getProjectsAction(
            {
              auth_token: selectedCustomerDetails.auth_token,
              queryParam: queryParamUnarchieved,
            },
            (response) => {
              let activityTypedata = new Set();
              response.forEach((projectData) => {
                projectData.data.settings.activity_type.map((activity) =>
                  activityTypedata.add(activity)
                );
              });
              let sertedactivityTypedata = _.sortBy(
                Array.from(activityTypedata),
                [(type) => type.toLowerCase()],
                ["asc"]
              );
              this.setState({
                allProjectActivityType: sertedactivityTypedata,
              });
            }
          );

          if (currentLocation === routesHelper.getActivityPath()) {
            // Get Prioritized activities
            getPrioritizedActivitiesForWorkspaceAction(
              {
                auth_token: selectedCustomerDetails.auth_token,
                user_id: parseInt(userDetails.id),
                activity_type: "ACTIVITY",
                is_archived: false,
                is_active: true,
              },
              (res) => {}
            );

            // Get Deprioritized activities
            getDeprioritizedActivitiesForWorkspaceAction(
              {
                auth_token: selectedCustomerDetails.auth_token,
                user_id: parseInt(userDetails.id),
                activity_type: "ACTIVITY",
                is_archived: false,
                is_active: true,
                page: 1,
                item_per_page: 50,
              },
              (res) => {}
            );
          }

          // Get Dashboard related data
          if (currentLocation === routesHelper.getDashboardPath()) {
            const customer_id = parseInt(selectedCustomerDetails.id);
            const from = moment()
              .subtract(1, "week")
              .format("YYYY-MM-DD");
            const to = moment()
              .subtract(1, "days")
              .format("YYYY-MM-DD");
            const queryParam = `?customer_id=${customer_id}&from_date=${from}&to_date=${to}`;
            if (
              getIsSuperAdmin(
                { id: parseInt(localStorage.getItem("id")) },
                selectedCustomerDetails
              )
            ) {
              getEffortOverviewAction({
                auth_token: selectedCustomerDetails.auth_token,
                queryParam,
              });
              getIndividualEffortAction({
                auth_token: selectedCustomerDetails.auth_token,
                queryParam: queryParam.concat("&type=individual-effort"),
              });
              getProjectAccessReqTeamAction({
                auth_token: selectedCustomerDetails.auth_token,
                queryParam: queryParam.concat("&type=project-access-request"),
              });
              getMissedTimeSheetsAction({
                auth_token: selectedCustomerDetails.auth_token,
                queryParam: `?customer_id=${customer_id}&from_date=${to}&to_date=${to}&type=missed-timesheet-entries`,
              });
              getProjectStatusAction({
                auth_token: selectedCustomerDetails.auth_token,
                queryParam: queryParam.concat(
                  "&type=project-status&project_critical=high"
                ),
              });
            } else {
              updateDashboardTabStateAction({ dashboardLanding: "Personal" });
            }
            getEffortOverviewPersonalAction({
              auth_token: localStorage.getItem("auth_token"),
              queryParam,
            });
            getProjectAccessReqPersonalAction({
              auth_token: localStorage.getItem("auth_token"),
              queryParam: queryParam.concat("&type=project-access-request"),
            });
            getLeaderBoardAction({
              auth_token: localStorage.getItem("auth_token"),
              queryParam: `?customer_id=${customer_id}&from_date=${moment()
                .startOf("month")
                .format("YYYY-MM-DD")}&to_date=${moment()
                .endOf("month")
                .format("YYYY-MM-DD")}&type=leaderboard`,
            });
          }

          if (
            currentLocation === routesHelper.getArchivedPath() ||
            currentLocation === routesHelper.getAllActivityArchivedPath()
          ) {
            getSelectedWorkpsaceArchivedActivities(
              {
                auth_token: selectedCustomerDetails.auth_token,
                user_id: parseInt(userDetails.id),
                activity_type: "ACTIVITY",
                is_archived: true,
                is_active: true,
                page: 1,
                item_per_page: 20,
              },
              (response) => {
                // write what to do on success
              }
            );
          }

          if (
            currentLocation === routesHelper.getArchivedPath() ||
            currentLocation === routesHelper.getAllProjectsArchivedPath()
          ) {
            let queryParam = getIsSuperAdmin(
              userDetails,
              selectedCustomerDetails
            )
              ? `?archived=true`
              : `?user_id=${parseInt(userDetails.id)}&archived=true`;
            getArchivedProjectsAction(
              { auth_token: selectedCustomerDetails.auth_token, queryParam },
              (response) => {}
            );
          }

          if (
            currentLocation === routesHelper.getArchivedPath() ||
            currentLocation === routesHelper.getAllCommentsArchivedPath()
          ) {
            getArchivedCommentsAction(
              { user_id: parseInt(userDetails.id), page: 1, perPage: 10 },
              (response) => {}
            );
          }

          // const checklistParam = `?is_archived=false`;
          // getChecklistAction({ queryParams: checklistParam }, response => {});

          if (currentLocation === routesHelper.getAllChecklistArchivedPath()) {
            const checklistArchivedParam = `is_archived=true`;
            getArchivedChecklistAction(
              { queryParams: checklistArchivedParam },
              (response) => {}
            );
          }

          // get total Activity Count
          // totalActvityCountAction({
          //   activity_type: "ACTIVITY",
          //   user_id: userDetails.id,
          //   auth_token: userDetails.auth_token
          // });

          // Fetch today's total worked hours of logged-in user for workspace
          fetchTimesheetAction(
            {
              from_date: moment()
                .startOf("day")
                .format("YYYY-MM-DD"),
              to_date: moment().format("YYYY-MM-DD"),
              user_id: userDetails.id,
            },
            (response) => {
              //console.log(response);
            }
          );

          // Get All types of notifications
          getGlobalNotificationsAction();
          getSystemNotificationsAction({
            auth_token: selectedCustomerDetails.auth_token,
          });
          let pageForNotification = 1;
          let per_pageForNotification = 10;
          let recipient_id = userDetails.id;
          getAllNotificationsAction(
            {
              auth_token: selectedCustomerDetails.auth_token,
              page: pageForNotification,
              per_page: per_pageForNotification,
              recipient_id,
            },
            (res) => {}
          );
          getYourNotificationsAction(
            {
              auth_token: selectedCustomerDetails.auth_token,
              page: pageForNotification,
              per_page: per_pageForNotification,
              recipient_id,
            },
            (res) => {}
          );
        }
      }
    );

    // this.fetchCustomerDetails();
  };

  askForPermissioToReceiveNotifications = () => {
    const messaging = firebase.messaging();

    Notification.requestPermission()
      .then(async (permission) => {
        if (permission === "granted") {
          try {
            if (!getFcmToken()) {
              const token = await messaging.getToken();
              if (token) {
                registerFirebaseUserToken(
                  { fcm_token: token, device_type: "web" },
                  (response) => {
                    setFcmToken(token);
                  }
                );
                console.log("try block", token);
                return token;
              } else {
                console.log(
                  "No Instance ID token available. Request permission to generate one."
                );
              }
            }
          } catch (error) {
            console.log("An error occurred while retrieving token. ", error);
            //BUT THE NEW TOKEN SUCCESSFULY FETCHED
            const token = await messaging.getToken();
            if (token) {
              registerFirebaseUserToken(
                { fcm_token: token, device_type: "web" },
                (response) => {
                  setFcmToken(token);
                }
              );
              console.log("catch block", token);
              return token;
            } else {
              console.log(
                "No Instance ID token available. Request permission to generate one."
              );
            }
          }
        }
      })
      .catch((error) => console.log(error));
  };

  componentDidMount() {
    const {
      getSystemNotificationsAction,
      getGlobalNotificationsAction,
      getNewAllNotificationAction,
      getNewYouNotificationAction,
      getTimerStatusForWorkspaceAction,
    } = this.props;

    this.askForPermissioToReceiveNotifications();

    setInterval(function() {
      let pageForNotification = 1;
      let per_pageForNotification = 10;
      let recipient_id = localStorage.getItem("id");
      if (localStorage.getItem("location")) {
        let location = JSON.parse(localStorage.getItem("location"));
        if (
          !moment(location.lastActiveOn).isSame(moment(), "day") ||
          location.lastUsedTimeZone !== moment.tz.guess()
        ) {
          let lastActiveZone = {
            lastActiveOn: moment().format("YYYY-MM-DD"),
            lastUsedTimeZone: moment.tz.guess(),
          };
          updateLastActive(
            {
              lastActiveOn: lastActiveZone.lastActiveOn,
              lastUsedTimeZone: lastActiveZone.lastUsedTimeZone,
            },
            (response) => {
              if (response.id)
                localStorage.setItem(
                  "location",
                  JSON.stringify(lastActiveZone)
                );
            }
          );
        }
      }

      getNewYouNotificationAction(
        {
          auth_token: localStorage.getItem("customer_auth_token"),
          page: pageForNotification,
          per_page: per_pageForNotification,
          recipient_id,
        },
        (res) => {
          if (!res.stdErr && res?.length >= 0) {
            // getGlobalNotificationsAction();

            // getSystemNotificationsAction({
            //   auth_token: localStorage.getItem("customer_auth_token")
            // });

            // getNewAllNotificationAction(
            //   {
            //     auth_token: localStorage.getItem("customer_auth_token"),
            //     page: pageForNotification,
            //     per_page: per_pageForNotification,
            //     recipient_id
            //   },
            //   res => {}
            // );
            if (res && res.length > 0) {
              res.forEach((notification) => {
                if (
                  notification.type === "ACTIVITY_TIMER" &&
                  notification.is_new
                )
                  getTimerStatusForWorkspaceAction({
                    auth_token: routesHelper.getAuthToken(),
                  });
              });
            }
          }
        }
      );
    }, 60000);
  }

  componentDidUpdate(prevProps, prevState) {
    const { router: currentRoute, searchContent } = this.props;

    const { router: prevRoute } = prevProps;
    if (
      prevRoute &&
      prevProps.location.pathname !== currentRoute.location.pathname &&
      searchContent
      // currentRoute.location.pathname === routesHelper.getRootPath()
    ) {
      // this.fetchCustomerDetails();
      this.refs.breadcrumb && this.refs.breadcrumb.onSearchCloseInitiated();
    }
  }

  fetchCustomerDetails = () => {
    const { getAllCustomersAction, getAllPendingListAction } = this.props;
    const auth_token = localStorage.getItem("auth_token");
    getAllCustomersAction({ auth_token }, (response) => {
      if (response && !response.error) {
      }
      getAllPendingListAction(auth_token, (pendingResponse) => {
        if (pendingResponse && !pendingResponse.error) {
          //  //console.log("costomers", pendingResponse)
        }
      });
    });
  };

  fetchTimeSheetForReport = () => {
    const { getTimeSheetsAction, currentUser } = this.props;
    let currentUserId = currentUser.id;
    let fromDateWithTime1 = moment(
      new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000)
    ).format("LL");
    let fromDateWithTime = moment(fromDateWithTime1)
      .add(0, "h")
      .add(0, "m")
      .add(0, "s");
    let fromDateUtc = moment(fromDateWithTime).format();
    let toDateUtc = moment(new Date()).format();
    let queryParam = `?from_date=${fromDateUtc.replace(
      "+",
      "%2B"
    )}&to_date=${toDateUtc.replace("+", "%2B")}&user_id=${currentUserId}`;
    getTimeSheetsAction({
      auth_token: localStorage.getItem("customer_auth_token"),
      queryParam,
    });
  };

  requestToJoinWorkSpace = (workspace, callback) => {
    const user_id = localStorage.getItem("id");
    workspace &&
      workspace.customer_id &&
      requestToJoinWorkspace(
        {
          customer_id: workspace.customer_id,
          requested_user_id: parseInt(user_id),
        },
        (response) => {
          if (response && !response.error && !response.stdErr) {
            this.fetchCustomerDetails();
            callback && callback({ id: workspace.customer_id });
          }
        }
      );
  };

  onTabClickedCallBack = () => {
    this.setState({
      timer: new Date().getTime(),
    });
  };

  getProjectDetails = () => {
    const projectDetails = this.props.projectList.filter(
      (project) => project.id === parseInt(this.props.params.projId)
    )[0];
    this.props.setDefaultProjectDetails(projectDetails);
    return projectDetails;
  };

  getGroupList = () => {
    const groupList = [
      ...new Set(
        this.props.projectList.reduce((acc, project) => {
          if (project.group) acc.push(project.group);
          return acc;
        }, [])
      ),
    ];
    return groupList;
  };

  onChangeBg = (imgObj) => {
    if (!imgObj.author) {
      localStorage.setItem("bg_image", JSON.stringify(imgObj));
      this.setState({
        style: imgObj.style,
        bgName: imgObj.bgName,
        credits_link: null,
        author_link: null,
        author: null,
      });
    } else if (imgObj.hasOwnProperty("randomize")) {
      this.setState({
        credits_link: imgObj.credits_link,
        author_link: imgObj.author_link,
        author: imgObj.author,
        style: imgObj.style,
        bgName: imgObj.bgName,
      });
      localStorage.removeItem("bg_image");
    } else {
      this.setState({
        credits_link: imgObj.credits_link,
        author_link: imgObj.author_link,
        author: imgObj.author,
        style: imgObj.style,
        bgName: imgObj.bgName,
      });
      localStorage.setItem("bg_image", JSON.stringify(imgObj));
    }
  };

  onArchiveUnarchiveProject = (project) => {
    let body = {
      id: project.id,
      archived: true,
    };
    this.props.unArchivedArchivedProject({ body }, (response) => {
      if (response && response.id) {
        this.props.router.push(routesHelper.getProjectsPath());
      }
    });
  };

  closeTour = (flag) => {
    if (flag) {
      this.setState({ isTourOpen: false });
    }
  };

  onTutorialClicked = () => {
    const { router } = this.props;
    if (router.getCurrentLocation().pathname !== "/activity") {
      router.push(routesHelper.getRootPath());
    }
    this.setState({ isTourOpen: true });
  };

  updateAcitvityType = (newActivityType, projectId) => {};

  getAllActivityType = () => {
    let newList = new Set();
    this.props.projectList.forEach((projectData) => {
      projectData.data.settings.activity_type.map((activity) =>
        newList.add(activity)
      );
    });
    let sertedactivityTypedata = _.sortBy(
      Array.from(newList),
      [(type) => type.toLowerCase()],
      ["asc"]
    );
    return sertedactivityTypedata;
  };

  render() {
    // const { author, author_link, style, credits_link } = getUnsplashImage();
    const userDetails = getProfileDetails();
    const {
      timer,
      bgName,
      showLoginAgainPopup,
      showRemovedFromWpPopup,
      author,
      author_link,
      style,
      credits_link,
    } = this.state;
    const {
      router,
      location,
      params,
      getUserDetailsAction,
      // customerLoaderState,
      workloadActivitiesLoaderState,
      effortOverviewLoaderState,
      individualEffortLoaderState,
      projectAccessReqTeamLoaderState,
      missedTimeSheetsLoaderState,
      effortOverviewPersonalLoaderState,
      projectAccessReqPersonalLoaderState,
      getEffortOverviewAction,
      getIndividualEffortAction,
      getProjectAccessReqTeamAction,
      getMissedTimeSheetsAction,
      getEffortOverviewPersonalAction,
      getProjectAccessReqPersonalAction,
      getProjectStatusAction,
      getLeaderBoardAction,
      clearDashboardReducerAction,
      pendingCustomerLoaderState,
      getAllPendingListAction,
      suggestWorkspaceActions,
      getAllPendingInviteListAction,
      getAllCustomer,
      getAllCustomersAction,
      listOfCustomer,
      getCustomerInfo,
      clearArchivedChecklistAction,
      // customerLoaderStateForDroDown,
      clearReducerWithPrioritizeAction,
      clearTimesheetAction,
      updateLoginAuth,
      // updateCustomerLoaderState,
      activitiesByProjectLoaderState,
      customerPhotoUpdateLoaderState,
      uploadPhotoToS3BucketAction,
      createWorkspaceActions,
      createWorkpsaceLoaderState,
      foundCustomersSuggestions,
      activityLoaderState,
      updatePrioritizedActivitiesLoaderState,
      updateDeprioritizedActivitiesLoaderState,
      updateSelectedWorkpaceDetailsAction,
      selectedCustomerDetails,
      getSelectedWorkpsaceActivities,
      tabState,
      dashboardTabState,
      updateTabStateAction,
      updateDashboardTabStateAction,
      updatedSearchTextAction,
      requestedToJoinWorkspace,
      listOfPendingCustomerInvites,
      navbarState,
      expandNavBar,
      updateUserProfileAction,
      currentUser,
      totalTimeForTheDay,
      fetchTimesheetAction,
      projectListLoaderState,
      getCommentsLoaderState,
      updateReportFilterAction,
      updateReportSortAction,
      timeSheetListLoaderState,
      projectList,
      searchContent,
      totalActvityCountAction,
      getSelectedWorkpsaceArchivedActivities,
      getProjectsAction,
      getArchivedProjectsAction,
      clearProjectsAction,
      getChecklistAction,
      getArchivedChecklistAction,
      getArchivedCommentsAction,
      getSystemNotificationsAction,
      getAllNotificationsAction,
      getYourNotificationsAction,
      getTimerStatusForWorkspaceAction,
      getPrioritizedActivitiesForWorkspaceAction,
      getDeprioritizedActivitiesForWorkspaceAction,
      getTimeSheetsAction,
      reportFilters,
      getSearchedArchivedActivitiesAction,
      clearSearchedArchivedActivitiesAction,
    } = this.props;
    const isOwner = getIsSuperAdmin(currentUser, selectedCustomerDetails);
    const currentLocation = router && router.location.pathname;
    // const shouldRenderDropDown = currentLocation !== routesHelper.getRootPath();
    const shouldRenderWorkspaceInvitations =
      currentLocation === routesHelper.getWorkspaceInvitationsPath();
    const shouldRenderFindWorkspace =
      currentLocation === routesHelper.getFindWorkspacePath();
    const shouldRenderCreateWorkspace =
      currentLocation === routesHelper.getCreateWorkspacePath();
    const shouldRenderAddUsersToWorkspace =
      currentLocation === routesHelper.getAddUsersToWorkspacePath();
    // const shouleRenderBreadCrumb =
    //   currentLocation !== routesHelper.getWorkspaceSettingsPath();
    const shouldRenderWorkSpaceSettings =
      currentLocation === routesHelper.getWorkspaceSettingsPath();
    const shouldRenerProfile =
      currentLocation === routesHelper.getUserProfilePath();
    const shouldRenderJoinWorkspace =
      currentLocation === routesHelper.getJoinWorkspacePath();
    const shouldRenderPeopleSettings =
      currentLocation === routesHelper.getPeopleSettings();
    const shouldRenderUnsplash =
      !shouldRenderFindWorkspace ||
      (shouldRenderFindWorkspace &&
        location.state &&
        location.state.from === "nav-bar" &&
        !shouldRenderCreateWorkspace &&
        !shouldRenderJoinWorkspace &&
        !shouldRenderAddUsersToWorkspace);
    const shoulRenderActivity =
      currentLocation === routesHelper.getActivityPath();
    const shouldRenderReport = currentLocation === routesHelper.getReportPath();
    const shouldRenderProjects =
      currentLocation === routesHelper.getProjectsPath();
    const shouldRenderDashboard =
      currentLocation === routesHelper.getDashboardPath();
    const shouldRenderArchived =
      currentLocation === routesHelper.getArchivedPath();
    const shouldRenderEditProject =
      currentLocation === routesHelper.getProjectEditPath(params.projId);
    // const shouldRenderWorkspace =
    //   customerLoaderState.fetched &&
    //   pendingCustomerLoaderState.fetched &&
    //   !shouldRenerProfile &&
    //   !shoulRenderActivity;

    const ShouldRenderArchivedAllActivities =
      currentLocation === routesHelper.getAllActivityArchivedPath();
    const shouldRenderArchivedAllProjects =
      currentLocation === routesHelper.getAllProjectsArchivedPath();
    const shouldRenderArchivedAllChecklist =
      currentLocation === routesHelper.getAllChecklistArchivedPath();
    const shouldRenderArchivedAllComments =
      currentLocation === routesHelper.getAllCommentsArchivedPath();
    const shouldRenderWorkload =
      currentLocation === routesHelper.workloadPath();
    const shouldRenderKanban =
      currentLocation === routesHelper.kanbanPath(params.projId);
    const shouldRenderSettings =
      currentLocation === routesHelper.getSettingsPath();
    let breadcrumbList =
      (shouldRenerProfile && [
        { name: "Account", link: "", type: "heading" },
      ]) ||
      (shoulRenderActivity && activities.breadCrumb) ||
      (shouldRenderReport && reports.breadCrumb) ||
      (shouldRenderProjects && projectConstants.breadCrumb) ||
      (shouldRenderDashboard && dashboardConstants.breadCrumb) ||
      (shouldRenderArchived && ArchivedConstant.breadCrumbMain) ||
      (shouldRenderKanban && kanbanConstant.defaultBreadcrumb) ||
      (ShouldRenderArchivedAllActivities &&
        ArchivedConstant.breadCrumbAllActivity) ||
      (shouldRenderArchivedAllProjects &&
        ArchivedConstant.breadCrumbAllProject) ||
      (shouldRenderArchivedAllChecklist &&
        ArchivedConstant.breadCrumbAllChecklists) ||
      (shouldRenderArchivedAllComments &&
        ArchivedConstant.breadCrumbAllComments) ||
      (shouldRenderWorkload && workloadConstant.defaultBreadcrumb) ||
      workspaceConstant.defaultBreadcrumb;
    const shouldLoad =
      ((customerPhotoUpdateLoaderState.fetching &&
        !shouldRenderProjects &&
        !shouldRenderEditProject) ||
        activityLoaderState.fetching ||
        updatePrioritizedActivitiesLoaderState.fetching ||
        updateDeprioritizedActivitiesLoaderState.fetching ||
        activitiesByProjectLoaderState.fetching ||
        // updateCustomerLoaderState.fetching ||
        workloadActivitiesLoaderState.fetching ||
        individualEffortLoaderState.fetching ||
        effortOverviewLoaderState.fetching ||
        projectAccessReqTeamLoaderState.fetching ||
        missedTimeSheetsLoaderState.fetching ||
        effortOverviewPersonalLoaderState.fetching ||
        projectAccessReqPersonalLoaderState.fetching ||
        getCommentsLoaderState.fetching ||
        timeSheetListLoaderState.fetching ||
        (timeSheetListLoaderState.fetched && timeSheetListLoaderState.error) ||
        projectListLoaderState.fetching) &&
      !shouldRenderFindWorkspace &&
      !shouldRenderWorkspaceInvitations &&
      !shouldRenderCreateWorkspace &&
      !shouldRenderJoinWorkspace &&
      !shouldRenderAddUsersToWorkspace &&
      !shouldRenderSettings;
    const navBarExpandedClass =
      shouldRenderJoinWorkspace ||
      shouldRenderFindWorkspace ||
      shouldRenderWorkspaceInvitations ||
      shouldRenderCreateWorkspace ||
      shouldRenderAddUsersToWorkspace
        ? true
        : navbarState;

    const steps = [
      {
        content: ({ goTo }) => (
          <ArrowBottom
            header="Activities"
            data="This is your activities page. All your activities would appear on this page."
            goTo={goTo}
            showPrev={false}
            prevStep={0}
            nextstep={1}
            step={0}
            closeTour={this.closeTour}
            classData="box arrow-bottom"
          />
        ),
        style: {
          "background-color": "transparent",
          width: "500px !important",
          "max-width": "none",
          position: "fixed",
          top: "-10px",
          left: "200px",
        },
      },
      {
        selector: '[data-tut="add-feature-container"]',
        content: ({ goTo }) => (
          <ArrowBottom
            header="Add activity"
            data="Click on the + button to add an activity. Added activities would display on this page."
            goTo={goTo}
            showPrev={true}
            prevStep={0}
            nextstep={2}
            step={1}
            closeTour={this.closeTour}
            classData="box arrow-bottom"
          />
        ),
        style: {
          "background-color": "transparent",
          width: "500px !important",
          "max-width": "none",
          left: "154px",
          top: "-45px",
        },
        stepInteraction: false,
      },
      {
        content: ({ goTo }) => (
          <ArrowTop
            header="Prioritized activities"
            data="Your prioritized activities would show up here. You can prioritize upto 3 activities."
            goTo={goTo}
            showPrev={true}
            prevStep={1}
            nextstep={3}
            step={2}
            closeTour={this.closeTour}
            finishButton={false}
          />
        ),
        style: {
          "background-color": "transparent",
          width: "500px !important",
          "max-width": "none",
          top: "100px",
          left: "-100px",
        },
      },
      {
        content: ({ goTo }) => (
          <ArrowBottom
            header="Deprioritized activities"
            data="Activities that are not prioritized at the moment would appear here."
            goTo={goTo}
            showPrev={true}
            prevStep={2}
            nextstep={4}
            step={3}
            closeTour={this.closeTour}
            classData="box arrow-bottom-left"
          />
        ),
        style: {
          "background-color": "transparent",
          width: "500px !important",
          "max-width": "none",
          top: "190px",
          left: "-140px",
        },
      },
      {
        selector: '[data-tut="navBar-workspace-holder"]',
        content: ({ goTo }) => (
          <ArrowTop
            header="Workspace"
            data="Add people, projects, billing info and modify workspace settings."
            goTo={goTo}
            showPrev={true}
            prevStep={3}
            nextstep={5}
            step={4}
            closeTour={this.closeTour}
            finishButton={false}
          />
        ),
        style: {
          "background-color": "transparent",
          width: "500px !important",
          "max-width": "none",
          left: "-185px",
          top: "25px",
        },
        stepInteraction: false,
      },
      {
        selector: '[data-tut="navBar-container"]',
        content: ({ goTo }) => (
          <ArrowTop
            header="Navigation"
            data="Easily access your team’s report, and workload, as well as your projects and archived items. "
            goTo={goTo}
            showPrev={true}
            prevStep={4}
            nextstep={6}
            finishButton={true}
            step={5}
            closeTour={this.closeTour}
          />
        ),
        style: {
          "background-color": "transparent",
          width: "500px !important",
          "max-width": "none",
          top: "350px",
          left: "-250px",
        },
        stepInteraction: false,
      },
      // ...
    ];

    let dialogPropsLoginAgain = {
      className: "dialog_wrapper",
      showPopup: showLoginAgainPopup || showRemovedFromWpPopup,
      dialogTitle: () =>
        showLoginAgainPopup
          ? "Session Expired"
          : "Access denied for the workspace",
      // onCloseCallBack: this.onCloseGooglePopup,
      shouldButtonEnable: true,
    };

    return (
      <div>
        {!shouldRenderPeopleSettings && (
          <div
            className="gtd-organization-container"
            style={{
              background: shouldRenderUnsplash && style,
            }}
          >
            {shouldLoad && (
              <div className="nw-att-loading-holder">
                <Loader size={100} loading={shouldLoad} />
              </div>
            )}
            {!shouldRenderPeopleSettings &&
              shouldRenderUnsplash &&
              !shouldRenderFindWorkspace &&
              !shouldRenderWorkspaceInvitations &&
              !shouldRenderJoinWorkspace &&
              !shouldRenderCreateWorkspace &&
              !shouldRenderAddUsersToWorkspace &&
              !shouldRenerProfile &&
              !shouldRenderSettings && (
                <SidebarNav
                  ref="sidebar"
                  getUserDetailsAction={getUserDetailsAction}
                  listOfCustomer={listOfCustomer}
                  getCustomerInfo={getCustomerInfo}
                  getWorkspaceUsers={getAllCustomer}
                  router={router}
                  selectedCustomerDetails={selectedCustomerDetails}
                  clearReducerWithPrioritizeAction={
                    clearReducerWithPrioritizeAction
                  }
                  clearTimesheetAction={clearTimesheetAction}
                  clearDashboardReducerAction={clearDashboardReducerAction}
                  updateSelectedWorkpaceDetailsAction={
                    updateSelectedWorkpaceDetailsAction
                  }
                  getTimerStatusForWorkspaceAction={
                    getTimerStatusForWorkspaceAction
                  }
                  getSelectedWorkpsaceActivities={
                    getSelectedWorkpsaceActivities
                  }
                  getPrioritizedActivitiesForWorkspaceAction={
                    getPrioritizedActivitiesForWorkspaceAction
                  }
                  getDeprioritizedActivitiesForWorkspaceAction={
                    getDeprioritizedActivitiesForWorkspaceAction
                  }
                  navbarState={navbarState}
                  expandNavBar={expandNavBar}
                  updateUserProfileAction={updateUserProfileAction}
                  updateLoginAuth={updateLoginAuth}
                  currentUser={currentUser}
                  fetchTimeSheetForReport={this.fetchTimeSheetForReport}
                  updateReportFilterAction={updateReportFilterAction}
                  updateReportSortAction={updateReportSortAction}
                  getProjectsAction={getProjectsAction}
                  updateTabState={updateTabStateAction}
                  updateDashboardTabState={updateDashboardTabStateAction}
                  totalActvityCountAction={totalActvityCountAction}
                  getSelectedWorkpsaceArchivedActivities={
                    getSelectedWorkpsaceArchivedActivities
                  }
                  getArchivedProjectsAction={getArchivedProjectsAction}
                  clearProjectsAction={clearProjectsAction}
                  getChecklistAction={getChecklistAction}
                  getArchivedChecklistAction={getArchivedChecklistAction}
                  clearArchivedChecklistAction={clearArchivedChecklistAction}
                  fetchTimesheetAction={fetchTimesheetAction}
                  getSystemNotificationsAction={getSystemNotificationsAction}
                  getAllNotificationsAction={getAllNotificationsAction}
                  getYourNotificationsAction={getYourNotificationsAction}
                ></SidebarNav>
              )}

            {/* <div className="nw-att-loading-holder">
          <ClipLoader sizeUnit={"px"} size={100} color={'#0190e9'} loading={shouldLoad} />
        </div> */}
            {!shouldRenderSettings && (
              <section
                className={
                  navbarState && !shouldRenderPeopleSettings
                    ? "page-right-section"
                    : shouldRenerProfile
                    ? "profile-section"
                    : "page-right-section on-navbar-closed"
                }
                style={navBarExpandedClass ? { width: "100%" } : {}}
              >
                <ToastContainer autoClose={2000} transition={Slide} />
                {!shouldRenderFindWorkspace &&
                  !shouldRenderWorkspaceInvitations &&
                  !shouldRenderCreateWorkspace &&
                  !shouldRenderJoinWorkspace &&
                  !shouldRenderAddUsersToWorkspace && (
                    <React.Fragment>
                      {!shouldRenderEditProject && !shouldRenerProfile && (
                        <div className="header-main-app">
                          <div className="gtd-title">
                            <BreadCrumb
                              ref="breadcrumb"
                              updatedSearchTextAction={updatedSearchTextAction}
                              getTimeSheetsAction={getTimeSheetsAction}
                              selectedCustomerDetails={selectedCustomerDetails}
                              loggedInUser={currentUser}
                              getSearchedArchivedActivitiesAction={
                                getSearchedArchivedActivitiesAction
                              }
                              getSelectedWorkpsaceArchivedActivities={
                                getSelectedWorkpsaceArchivedActivities
                              }
                              clearSearchedArchivedActivitiesAction={
                                clearSearchedArchivedActivitiesAction
                              }
                              getArchivedCommentsAction={
                                getArchivedCommentsAction
                              }
                              reportFilters={reportFilters}
                              searchContent={searchContent}
                              onTabClickedCallBack={this.onTabClickedCallBack}
                              tabState={tabState}
                              isOwner={isOwner}
                              dashboardTabState={dashboardTabState}
                              updateTabState={updateTabStateAction}
                              updateDashboardTabState={
                                updateDashboardTabStateAction
                              }
                              breadcrumbList={breadcrumbList}
                              router={router}
                            />
                          </div>
                          <div className="performa">
                            {(shoulRenderActivity ||
                              shouldRenderReport ||
                              shouldRenderProjects ||
                              shouldRenderDashboard ||
                              shouldRenderArchived ||
                              ShouldRenderArchivedAllActivities ||
                              shouldRenderArchivedAllProjects ||
                              shouldRenderWorkload ||
                              shouldRenderKanban ||
                              ShouldRenderArchivedAllActivities ||
                              shouldRenderArchivedAllProjects ||
                              shouldRenderArchivedAllChecklist ||
                              shouldRenderArchivedAllComments) && (
                              <PerformaActions
                                totalTimeForTheDay={totalTimeForTheDay}
                                router={router}
                                selectedCustomerDetails={
                                  selectedCustomerDetails
                                }
                                userDetails={userDetails}
                              />
                            )}
                            <UserActionDropDown
                              router={router}
                              currentUser={currentUser}
                              selectedCustomerDetails={selectedCustomerDetails}
                              onTutorialClicked={this.onTutorialClicked}
                            />
                          </div>
                        </div>
                      )}
                      {(shouldRenderEditProject || shouldRenerProfile) && (
                        <ProjectEditHeader
                          router={router}
                          updatedSearchTextAction={updatedSearchTextAction}
                          selectedCustomerDetails={selectedCustomerDetails}
                          currentUser={currentUser}
                          updateTabState={updateTabStateAction}
                          totalTimeForTheDay={totalTimeForTheDay}
                          shouldRenderProfile={shouldRenerProfile}
                          shouldRenderEditProject={shouldRenderEditProject}
                        />
                      )}
                      {/* <div className="breadcrumb">
                  {shouldRenderDropDown && (
                    <WorkspaceDropDown
                      router={router}
                      selectedCustomerDetails={selectedCustomerDetails}
                      getAllCustomersAction={getAllCustomersAction}
                      listOfCustomer={listOfCustomer}
                      customerLoaderStateForDroDown={
                        customerLoaderStateForDroDown
                      }
                      updateSelectedWorkpaceDetailsAction={
                        updateSelectedWorkpaceDetailsAction
                      }
                      getSelectedWorkpsaceActivities={
                        getSelectedWorkpsaceActivities
                      }
                    />
                  )}
                  {/* {shouleRenderBreadCrumb && (
                    <BreadCrumb
                      updatedSearchTextAction={updatedSearchTextAction}
                      onTabClickedCallBack={this.onTabClickedCallBack}
                      breadcrumbList={breadcrumbList}
                      router={router}
                    />
                  )}
                </div> */}
                      {shoulRenderActivity && (
                        <div
                          className="activity-create-container"
                          id="activity-container-scroll-div"
                        >
                          <Activity
                            activityLoaderState={activityLoaderState}
                            getSelectedWorkpsaceActivities={
                              getSelectedWorkpsaceActivities
                            }
                            router={router}
                            updateSelectedWorkpaceDetailsAction={
                              updateSelectedWorkpaceDetailsAction
                            }
                            timer={timer}
                            getTimerStatusForWorkspaceAction={
                              getTimerStatusForWorkspaceAction
                            }
                            fetchTimesheetAction={fetchTimesheetAction}
                            onWorkspaceNavBarClicked={
                              this.refs.sidebar &&
                              this.refs.sidebar.onWorkspaceNavBarClicked
                            }
                          />
                        </div>
                      )}
                      {shouldRenderReport && (
                        <ReportLanding
                          projectList={projectList}
                          currentUser={currentUser}
                          currentWorkSpace={selectedCustomerDetails}
                          getSelectedWorkpsaceActivities={
                            getSelectedWorkpsaceActivities
                          }
                          fetchTimesheetAction={fetchTimesheetAction}
                          router={router}
                          routerState={router.location.state}
                        />
                      )}
                      {shouldRenderProjects && (
                        <ProjectsLanding
                          currentUser={currentUser}
                          projectList={projectList}
                          // currentWorkSpace={selectedCustomerDetails}
                          // selectedCustomerDetails = {selectedCustomerDetails}
                          router={router}
                          updateSelectedWorkpaceDetailsAction={
                            updateSelectedWorkpaceDetailsAction
                          }
                        />
                      )}
                      {shouldRenderEditProject && (
                        <div className="edit-project-detail-container">
                          <div className="ppl-settings-control-container">
                            <Scrollbar className="edit-proj-scroll">
                              <ProjectSettingsForm
                                projectDetails={this.getProjectDetails()}
                                groupList={this.getGroupList()}
                                currentWorkSpace={selectedCustomerDetails}
                                currencyLogo={getCurrencyLogo(
                                  selectedCustomerDetails
                                )}
                                edit={"edit"}
                                onArchiveUnarchive={
                                  this.onArchiveUnarchiveProject
                                }
                                allProjectActivityType={this.getAllActivityType()}
                                updateAcitvityType={this.updateAcitvityType}
                              />
                              <PeopleAccessSection
                                projectDetails={this.getProjectDetails()}
                                currentWorkSpace={selectedCustomerDetails}
                              />
                            </Scrollbar>
                          </div>
                        </div>
                      )}
                      {shouldRenderDashboard && (
                        <DashboardLanding
                          currentWorkSpace={selectedCustomerDetails}
                          currentUser={currentUser}
                          dashboardTabState={dashboardTabState}
                          updateDashboardTabStateAction={
                            updateDashboardTabStateAction
                          }
                          getEffortOverviewAction={getEffortOverviewAction}
                          getIndividualEffortAction={getIndividualEffortAction}
                          getProjectAccessReqTeamAction={
                            getProjectAccessReqTeamAction
                          }
                          getMissedTimeSheetsAction={getMissedTimeSheetsAction}
                          getEffortOverviewPersonalAction={
                            getEffortOverviewPersonalAction
                          }
                          getProjectAccessReqPersonalAction={
                            getProjectAccessReqPersonalAction
                          }
                          getProjectStatusAction={getProjectStatusAction}
                          getLeaderBoardAction={getLeaderBoardAction}
                          clearDashboardReducerAction={
                            clearDashboardReducerAction
                          }
                          router={router}
                        />
                      )}
                      {shouldRenderArchived && (
                        <ArchivedLanding
                          getSelectedWorkpsaceArchivedActivities={
                            getSelectedWorkpsaceArchivedActivities
                          }
                          getArchivedProjectsAction={getArchivedProjectsAction}
                          getArchivedChecklistAction={
                            getArchivedChecklistAction
                          }
                          getArchivedCommentsAction={getArchivedCommentsAction}
                          router={router}
                        ></ArchivedLanding>
                      )}
                      {ShouldRenderArchivedAllActivities && (
                        <MoreActivity router={router}></MoreActivity>
                      )}
                      {shouldRenderArchivedAllProjects && (
                        <MoreArchivedProjects></MoreArchivedProjects>
                      )}
                      {shouldRenderArchivedAllChecklist && (
                        <MoreChecklist></MoreChecklist>
                      )}
                      {shouldRenderArchivedAllComments && (
                        <MoreComments></MoreComments>
                      )}
                      {shouldRenderWorkload && (
                        <Workload
                          router={router}
                          fetchTimesheetAction={fetchTimesheetAction}
                        ></Workload>
                      )}
                      {shouldRenderKanban && (
                        <Kanban
                          router={router}
                          fetchTimesheetAction={fetchTimesheetAction}
                        ></Kanban>
                      )}
                      {/* {shouldRenderWorkspace && (
                      <Workspace
                        router={router}
                        updateSelectedWorkpaceDetailsAction={
                          updateSelectedWorkpaceDetailsAction
                        }
                        getSelectedWorkpsaceActivities={
                          getSelectedWorkpsaceActivities
                        }
                        requestToJoinWorkspace={this.requestToJoinWorkSpace}
                      />
                    )}{" "} */}
                      {shouldRenerProfile && (
                        <div className="user-profile-parent">
                          <UserProfile
                            currentWorkSpace={selectedCustomerDetails}
                            currentUser={currentUser}
                            updateLoginAuth={updateLoginAuth}
                            onChangeBg={this.onChangeBg}
                            bgName={bgName}
                          />
                        </div>
                      )}
                    </React.Fragment>
                  )}
                {shouldRenderFindWorkspace && (
                  <FindWorkspace
                    getAllPendingListAction={getAllPendingListAction}
                    getAllPendingInviteListAction={
                      getAllPendingInviteListAction
                    }
                    listOfPendingCustomerInvites={listOfPendingCustomerInvites}
                    requestedToJoinWorkspace={requestedToJoinWorkspace}
                    requestToJoinWorkSpace={this.requestToJoinWorkSpace}
                    totalTimeForTheDay={totalTimeForTheDay || null}
                    currentUser={currentUser || null}
                    selectedCustomerDetails={selectedCustomerDetails || null}
                    router={router}
                  />
                )}
                {shouldRenderWorkspaceInvitations && (
                  <WorkspaceInvitations
                    getAllPendingInviteListAction={
                      getAllPendingInviteListAction
                    }
                    suggestWorkspaceActions={suggestWorkspaceActions}
                    pendingCustomerLoaderState={pendingCustomerLoaderState}
                    listOfPendingCustomerInvites={listOfPendingCustomerInvites}
                    getAllCustomersAction={getAllCustomersAction}
                    totalTimeForTheDay={totalTimeForTheDay || null}
                    currentUser={currentUser || null}
                    selectedCustomerDetails={selectedCustomerDetails || null}
                    listOfCustomer={listOfCustomer}
                    router={router}
                  />
                )}
                {shouldRenderJoinWorkspace && (
                  <JoinWorkspace
                    requestToJoinWorkspace={this.requestToJoinWorkSpace}
                    router={router}
                    foundCustomersSuggestions={foundCustomersSuggestions}
                  />
                )}
                {shouldRenderCreateWorkspace && (
                  <CreateWorkspace
                    createWorkpsaceLoaderState={createWorkpsaceLoaderState}
                    createWorkspaceAction={createWorkspaceActions}
                    uploadPhotoToS3BucketAction={uploadPhotoToS3BucketAction}
                    getAllCustomersAction={getAllCustomersAction}
                    updateSelectedWorkpaceDetailsAction={
                      updateSelectedWorkpaceDetailsAction
                    }
                    router={router}
                  />
                )}
                {shouldRenderAddUsersToWorkspace && (
                  <AddUsersToWorkspace router={router} />
                )}
                {shouldRenderWorkSpaceSettings && <WorkspaceSettings />}
                {!shouldRenderEditProject &&
                  !shouldRenderFindWorkspace &&
                  !shouldRenderCreateWorkspace &&
                  !shouldRenderAddUsersToWorkspace &&
                  !shouldRenderReport &&
                  !shouldRenderWorkload &&
                  !shouldRenerProfile &&
                  !shouldRenderKanban &&
                  credits_link && (
                    <div className="unslpash-credits clients-credits">
                      Photo by{" "}
                      <a
                        href={author_link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {author}
                      </a>
                      {author && "/"}
                      <a
                        href={credits_link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Unsplash
                      </a>
                    </div>
                  )}
              </section>
            )}
            {shouldRenderSettings && <SettingsLanding router={router} />}
          </div>
        )}
        {shouldRenderPeopleSettings && <PeopleSettings router={router} />}
        <Tour
          showNumber={false}
          steps={steps}
          startAt={0}
          isOpen={this.state.isTourOpen}
          className="helper"
          onRequestClose={() => this.closeTour(false)}
          showNavigation={false}
          showButtons={false}
          showCloseButton={false}
        />
        {(showLoginAgainPopup || showRemovedFromWpPopup) && (
          <DialogWrapper dialogProps={dialogPropsLoginAgain}>
            <ChangeGooglePopup
              loginAgain={showLoginAgainPopup}
              passwordChangedOtherPlatform={showLoginAgainPopup}
              showRemovedFromWpPopup={showRemovedFromWpPopup}
              onClosePopup={this.onCloseLoginPopup}
            />
          </DialogWrapper>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchProps)(App);
