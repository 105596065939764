import React, { useState, useCallback, useEffect, memo } from "react";
import ChecklistItem from "./checklistItem";
import _ from "lodash";
import CheckListHelper from "./checklistHelper";

function ChecklistItemRearrangeMain({
  showCompleted,
  isSuperAdmin,
  activityDetails,
  isUserPartOfCard,
  checklistItemId,
  checklistItems,
  users,
  updateChecklistItemAction,
  toHideAddItemInput,
  clickedChecklistId,
  onchecklistUpdated,
  onMoveOfItemToAnotherList,
  checklistId,
  setChecklist,
  checklistsArray,
}) {
  const [cards, setCards] = useState(checklistItems);
  const [draggingParent, setDraggingParent] = useState(false);

  const moveCard = useCallback(
    (
      _dragIndex,
      hoverIndex,
      _dragList,
      _hoverList,
      item,
      currentCheckListId,
      sourceCheckListId
    ) => {
      
      let cardsCopy = _.cloneDeep(checklistItems);
      let updatedCards;

      let sentChecklist = {
        checklist_id: item.checklist_id,
        completed_by: item.item,
        created_at: item.created_at,
        created_by: item.created_by,
        data: item.data,
        id: item.id,
        is_archived: item.is_archived,
        is_completed: item.is_completed,
        position: item.position,
        required: item.required,
        required_output: item.required_output,
        social_data: item.social_data,
        title: item.title,
        updated_at: item.updated_at,
      };

      if (currentCheckListId === checklistId) {
        updatedCards = cardsCopy.filter(
          (checklistItem) => checklistItem.id !== item.id
        );
        updatedCards.splice(hoverIndex, 0, sentChecklist);
      } else {
        let checklistsArrayCopy = _.cloneDeep(checklistsArray);
        updatedCards = cardsCopy.filter(
          (checklistItem) => checklistItem.id !== item.id
        );
        updatedCards.splice(hoverIndex, 0, sentChecklist);
        checklistsArrayCopy.forEach((checklistData) => {
          if (
            checklistData.id !== checklistId &&
            checklistData.id !== sourceCheckListId
          ) {
            let checklistitems = checklistData.checklist_items.filter(
              (checklistItem) => checklistItem.id !== item.id
            );
            checklistData.checklist_items = checklistitems;
          } else if (checklistData.id === checklistId) {
            checklistData.checklist_items = updatedCards;
          }
        });
        setChecklist(checklistsArrayCopy);
      }
      setCards(updatedCards);
    },
    [cards]
  );

  useEffect(() => {
    setCards(checklistItems);
  }, [checklistItems]);

  

  const renderCard = (card, index) => {
    if (!showCompleted) {
      if (!card.is_completed) {
        return (
          <ChecklistItem
            key={`${card.id}`}
            toHideAddItemInput={toHideAddItemInput}
            activityDetails={activityDetails}
            isUserPartOfCard={isUserPartOfCard}
            isSuperAdmin={isSuperAdmin}
            checklistItemId={checklistItemId}
            checklistItem={card}
            checklistItems={cards}
            index={index}
            users={users}
            updateChecklistItemAction={updateChecklistItemAction}
            clickedChecklistId={clickedChecklistId}
            moveCard={moveCard}
            onMoveOfItemToAnotherList={onMoveOfItemToAnotherList}
            onchecklistUpdated={onchecklistUpdated}
            showCompleted={showCompleted}
            checklistId={checklistId}
            setChecklist={setChecklist}
            previewFlag={false}
            checklistsArray={checklistsArray}
            draggingParent={draggingParent}
            setDraggingParent={setDraggingParent}
          ></ChecklistItem>
        );
      }
    } else {
      return (
        <ChecklistItem
          key={`${card.id}`}
          toHideAddItemInput={toHideAddItemInput}
          activityDetails={activityDetails}
          checklistItem={card}
          isUserPartOfCard={isUserPartOfCard}
          isSuperAdmin={isSuperAdmin}
          checklistItemId={checklistItemId}
          checklistItems={cards}
          index={index}
          users={users}
          updateChecklistItemAction={updateChecklistItemAction}
          clickedChecklistId={clickedChecklistId}
          moveCard={moveCard}
          onMoveOfItemToAnotherList={onMoveOfItemToAnotherList}
          onchecklistUpdated={onchecklistUpdated}
          showCompleted={showCompleted}
          checklistId={checklistId}
          setChecklist={setChecklist}
          previewFlag={false}
          checklistsArray={checklistsArray}
          draggingParent={draggingParent}
          setDraggingParent={setDraggingParent}
        ></ChecklistItem>
      );
    }
  };

  let checkListRender = null;
  if (cards && cards.length > 0) {
    checkListRender = cards.map((card, i) => renderCard(card, i));
  } else {
    checkListRender = (
      <CheckListHelper
        updateChecklistItemAction={updateChecklistItemAction}
        onchecklistUpdated={onchecklistUpdated}
        onMoveOfItemToAnotherList={onMoveOfItemToAnotherList}
        checklistId={checklistId}
        setChecklist={setChecklist}
      />
    );
  }
  return <div>{checkListRender}</div>;
}

export default memo(ChecklistItemRearrangeMain);
